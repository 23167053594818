import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getAllProducts, getAttributes } from "../../reducers/commonReducer";
import moment from "moment";
import ProductDetail from "./ProductDetail";

const ProductList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const catid = queryParameters.get("catid");
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getAllProducts());
    dispatch(getAttributes())
  }, []);
  const { getAllProductsData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={12}>
            <h3>Product List</h3>
          </Col>
          <hr />
          <Col md={6} sm={6} xs={6}>
           <Link to={`/products-action`}>
            <Button variant="success">Add Product</Button>
           </Link>
          </Col>
          <Col className="text-end" md={6} sm={6} xs={6}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
                dispatch(
                  getAllProducts({
                    search:
                      e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1),
                  })
                );
              }}
            />
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Plant</th>
                <th>Date</th>
                <th>Product Name</th>
                <th>Qty</th>
                <th>Img</th>
                <th>Variants</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!getAllProductsData
                ? getAllProductsData?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="Sr">{index + 1}.</td>
                        <td data-label="Plant">{data?.category?.name}</td>
                        <td data-label="Date">{moment(data?.createdAt).format("MMM Do YY")}</td>
                        <td data-label="Product Name">{data?.name}</td>
                        <td data-label="Product Name">{data?.quantity}</td>
                        <td data-label="Img"><img className="w-auto h-100px" src={data?.image}/></td>
                        <td data-label="Variants">
                          {
                            data?.variants && <ProductDetail title="View" heading={data?.name} variants={data?.variants} status={data?.status}/>
                          }
                        </td>
                        <td data-label="Status">
                          {data?.status === true ? (
                            <b className="text-success">Active</b>
                            ) : (
                              <b className="text-danger">Inactive</b>
                          )}
                        </td>
                        <td data-label="Action">
                          <Link to={`/products-action?pid=${data?.id}`}>
                            <Button variant="info">Update</Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                : "No record found"}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default ProductList;
