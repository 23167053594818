import React, { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCartList, getUser, userLogOut } from "../reducers/commonReducer";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import AP from "../images/logo.png";
import { Col, Row } from "react-bootstrap";

const HeaderMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  useEffect(() => {
    if(localStorage.getItem("userRole") === "dealer"){
      dispatch(getCartList())
    }
    dispatch(getUser())
  },[])
  const { cartListCount, getUserDetails } = useSelector((state) => state.commonReducer);
  const logout = async () => {
    const apiResponse = await dispatch(userLogOut());
    if (apiResponse?.payload?.status === 1) {
      localStorage.clear();
      return navigate("/");
    }
    return null;
  };
  return (
    <>
      <div className="container">
        <Row className={`mt-3 mb-3 ${width.current > 500 ? `align-baseline` : `align-center`}`}>
          <Col md={6} sm={4} xs={4}>
            <Navbar.Brand>
              <NavLink to="/orders">
                <img className={width.current > 500 ? "w-auto" : "w-100"} src={AP} />
              </NavLink>
            </Navbar.Brand>
          </Col>
          <Col md={6} sm={8} xs={8} className="text-end">
            {
              (width.current < 500 && localStorage.getItem("userRole") === "dealer") ?
              <Link to={cartListCount > 0 && "/cart-list"} className="d-flex float-right">
                    <h3 className={`px-2 ${width.current > 500 ? `mrgn-20m` : `mrgn-12m` }`}>
                    <i className="bi bi-cart-fill"></i> 
                    </h3>
                    <span className="cartValue p-1">
                    <b>{cartListCount}</b>
                    </span>
                    </Link> : <Button type="submit" variant="danger" onClick={logout}>
              Log Out{" "}
            </Button>
            }
          </Col>
        </Row>
      </div>

      <Navbar
        collapseOnSelect
        className="header-bg-color"
        expand="lg"
        variant="dark"
      >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
           {width.current < 500 && <p className="mb-0 mx-2"><b>Welcome {getUserDetails?.name}</b></p>}
            <Nav className="me-auto menu-bar dashboard">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {
                  (localStorage.getItem("userRole") === "admin" || localStorage.getItem("userRole") === "superadmin") && <>
                  {/* <li className="nav-item">
                  <NavLink to="/dashboard">
                  <i className="bi bi-speedometer"></i>
                    Dashborad
                  </NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink to="/users">
                    <i className="bi bi-people"></i>
                    Users
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/stock">
                    <i className="bi bi-bag-dash"></i>
                    Stock
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/orders">
                  <i className="bi bi-border-width"></i>
                    Orders
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/payment">
                  <i className="bi bi-credit-card-fill"></i>
                    Transaction
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/products">
                    <i className="bi bi-bag-dash"></i>
                    Product
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/attribute">
                    <i className="bi bi-bag-dash"></i>
                    Attribute
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/replacement-warranty">
                    <i className="bi bi-bag-dash"></i>
                    Replacement & Warranty
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/category">
                  <i className="bi bi-border-width"></i>
                  Plant
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/settings">
                  <i className="bi bi-gear-fill"></i>
                    Settings
                  </NavLink>
                </li> */}
                  </>
                }
                {/* accountant menu */}
                {localStorage.getItem("userRole") === "accountant" && <>
                <li className="nav-item">
                  <NavLink to="/users">
                    <i className="bi bi-people"></i>
                    User
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/payment">
                    <i className="bi bi-people"></i>
                    Transaction
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/stock">
                    <i className="bi bi-bag-dash"></i>
                    Stock
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/replacement-warranty">
                    <i className="bi bi-bag-dash"></i>
                    Replacement & Warranty
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/products">
                    <i className="bi bi-bag-dash"></i>
                    Product
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/attribute">
                    <i className="bi bi-bag-dash"></i>
                    Attribute
                  </NavLink>
                </li>
                  </>
                }
                {/* manager menu */}
                {localStorage.getItem("userRole") === "manager" && <>
                <li className="nav-item">
                  <NavLink to="/users">
                    <i className="bi bi-people"></i>
                    User
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/orders">
                  <i className="bi bi-border-width"></i>
                    Orders
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/stock">
                    <i className="bi bi-bag-dash"></i>
                    Stock
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/replacement-warranty">
                    <i className="bi bi-bag-dash"></i>
                    Replacement & Warranty
                  </NavLink>
                </li>
                  </>
                }
                {/* manager menu */}
                {localStorage.getItem("userRole") === "marketing" && <>
                <li className="nav-item">
                  <NavLink to="/users">
                    <i className="bi bi-people"></i>
                    User
                  </NavLink>
                </li>
                  </>
                }
                {/* dealer menu */}
                {localStorage.getItem("userRole") === "dealer" && <>
                <li className="nav-item">
                  <NavLink to="/orders">
                  <i className="bi bi-border-width"></i>
                    Orders
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/products-detail">
                  <i className="bi bi-bag-check-fill"></i>
                    Shop
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/payment">
                  <i className="bi bi-credit-card-fill"></i>
                    Transaction
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/replacement-warranty">
                    <i className="bi bi-bag-dash"></i>
                    Replacement & Warranty
                  </NavLink>
                </li>
                {
                  width.current < 500 && <li className="mt-3">
                  <NavLink>
                   <Button type="submit" variant="danger" className="w-100 text-white border" onClick={logout}>Log Out <i className="bi bi-box-arrow-right"></i></Button>
                  </NavLink>
                </li>
                }
                  </>
                }
              </ul>
            </Nav>
            <Nav>
            {width.current > 500 && <Nav.Link className="bg-success rounded"><b className="text-white">Welcome {getUserDetails?.name}</b></Nav.Link>}
            </Nav>
            {(localStorage.getItem("userRole") === "dealer" && width.current > 500) &&
            <Nav>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to={cartListCount > 0 && "/cart-list"} className="d-flex align-center">
                    <h3 className="px-2 mrgn-20m">
                    <i className="bi bi-cart-fill text-white"></i> 
                    </h3>
                    <span className="cartValue p-1">
                    <b>{cartListCount}</b>
                    </span>
                    </Link>
                </li>
              </ul>
            </Nav>}
          </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default HeaderMenu;
