import React, { useRef, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {  addReplacement, replacementList, updateReplacement } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  // name: yup.string().required(),
  // status: yup.string().required(),
});

function ReplacementWarrantyAction(props) {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const [show, setShow] = useState(false);
  const {
    title,
    status,
    btnTitle,
    variant,
    orderid,
    productName,
    replacedWith,
    comment,srNumber,
    search,otherProduct,id
  } = props;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant={"warning"} className={`float-right ${width.current < 500 ? 'w-100' : ''}`} onClick={handleShow}>
        {btnTitle}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            id ? await dispatch(updateReplacement(values)) : await dispatch(addReplacement(values));
            resetForm({ values: "" });
            dispatch(replacementList())
            setShow(false)
          }}
          initialValues={{
            variant,
            orderid,
            productName,
            otherProduct,
            srNumber:srNumber ? srNumber : search,
            replacedWith,
            comment,
            status,id
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
              <Col md={6}>
                  <Form.Label>Product</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("productName", e.target.value);
                    }}
                    name="productName"
                    className="form-control"
                    value={values.productName}
                    isInvalid={!!errors.productName}
                  > 
                    <option value="">Select Product</option>
                    <option value={"Controller"}>Controller</option>
                    <option value={"Moter"}>Moter</option>
                    <option value={"Charger"}>Charger</option>
                    <option value={"Battery"}>Battery</option>
                    <option value={"Other"}>Other</option>
                  </Form.Control>
                </Col>
                {
                  values.productName === "Other" && <Col md={6} sm={6}>
                  <Form.Group >
                    <Form.Label>Other Product</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Other"
                      name="otherProduct"
                      value={values.otherProduct}
                      onChange={handleChange}
                      isInvalid={!!errors.otherProduct}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.otherProduct}
                    </Form.Control.Feedback>
                  </Form.Group>  
                </Col>
                }
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={6}>
                  <Form.Group >
                    <Form.Label>Sr Number</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Sr Number"
                      name="srNumber"
                      value={values.srNumber}
                      onChange={handleChange}
                      isInvalid={!!errors.srNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.srNumber}
                    </Form.Control.Feedback>
                  </Form.Group>  
                </Col>
                <Col md={6} sm={6}>
                  <Form.Group >
                    <Form.Label>Replaced With</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Replaced With"
                      name="replacedWith"
                      value={values.replacedWith}
                      onChange={handleChange}
                      isInvalid={!!errors.replacedWith}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.replacedWith}
                    </Form.Control.Feedback>
                  </Form.Group>  
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  > 
                    <option value="">Select Status</option>
                    <option value={"Pending"}>Pending</option>
                    <option value={"Accept"}>Accept</option>
                    <option value={"Reject"}>Reject</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group >
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      as="textarea"
                      row={3}
                      placeholder="Write Your Comment"
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      isInvalid={!!errors.comment}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.comment}
                    </Form.Control.Feedback>
                  </Form.Group>  
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default ReplacementWarrantyAction;
