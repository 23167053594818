import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';

function OrderDetails({title, variants}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const width = useRef(window.innerWidth);
  const { getAttributesList, varientsList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Button variant="warning" onClick={handleShow}>
        {title}
      </Button>
      <Modal size={"lg"} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <hr className='m-0'/>
        <Modal.Body>
          <Table striped>
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>Name</th>
                <th>Variant</th>
                <th>Color</th>
                <th>Qty</th>
                {
                  (localStorage.getItem("userRole") !== "manager") && <><th>Price</th>
                  <th>Total</th>
                  </>
                }
              </tr>
            </thead>
            <tbody>
              {
                !!variants && variants?.map((data, index) => {
                  return(
                    <tr key={index}>
                      <td data-label="Sr">{index + 1}.</td>
                      <td data-label="Name" className='col-md-4'>{data?.varient?.product?.name}</td>
                      <td data-label="Variant">
                        {!!data?.varient?.attributes && Object.entries(data?.varient?.attributes).map(([key, value]) => <p key={key} className={`mb-0 ${width.current < 500 ? `f-12` : ''}`}>
                            {!!getAttributesList && getAttributesList?.[key]}: <strong>{value}</strong>
                            </p>
                        )}
                      </td>
                      <td data-label="Color">{data?.color}</td>
                      <td data-label="Qty">{data?.qty}</td>
                      {
                        (localStorage.getItem("userRole") !== "manager") && <>
                        <td data-label="Price">{varientsList?.[data?.varient?.id] ? varientsList?.[data?.varient?.id] : data?.price}/-</td>
                        <td data-label="Total">{data?.total}/-</td>
                        </>
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" className='w-100' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrderDetails;