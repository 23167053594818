import React, { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FieldArray, Formik } from "formik";
import * as yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Header from "../../Components/Header";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, categoryList, getEnableAttribute, singleProduct, updateProduct } from "../../reducers/commonReducer";
import { useNavigate } from "react-router-dom";


const schema = yup.object().shape({
  name: yup.string().required("Please Enter Product Name."),
  quantity : yup.number().required("Please Enter Product quantity."),
  category : yup.string().required("Please Select Plant."),
});
function ProductAction() {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const productId = queryParameters.get("pid");
  const navigate = useNavigate();
  useEffect(() => {
    if(productId){
      dispatch(singleProduct({
        id:productId
      }))
    }
    dispatch(getEnableAttribute())
    dispatch(categoryList())
  },[])
  const { singleProductData, categoryListData, getEnableAttributeList } = useSelector((state) => state.commonReducer);
  return (
    <>
    <Header/>
        <Formik
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            const result = productId ? await dispatch(updateProduct(values)) : await dispatch(addProduct(values));
            if(result?.payload?.status){
              setTimeout(() => {
                navigate(`/products`)
                window.location.reload(true)
              }, 500)
            }
            resetForm({ values: "" });
          }}
          initialValues={{
            name : singleProductData?.name,
            id : productId,
            status : singleProductData?.status ? singleProductData?.status : true,
            price: singleProductData?.price,
            quantity: singleProductData?.quantity,
            category: singleProductData?.category,
            variants: singleProductData?.variants
            ? singleProductData?.variants?.map((item) => ({
              quantity: item?.quantity,
              price: item?.price,
              status: item?.status,
              id: item?.id,
              attributes: item?.attributes
                && Object.fromEntries(
                    Object.entries(item?.attributes)?.map(([key, value]) => 
                    [
                      key,
                      value,
                    ]
                    )
                  ),
              }))
            : [{ quantity: "", price: "", status:''}],
            description: singleProductData?.description
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <h3 className="text-danger mt-2 mb-0 text-center">Please use Img Width 355px and Height 355px otherwise designing issue.</h3>
              <Row className="mb-3 mt-3">
                <Col md={3} className="mb-1">
                  <Form.Group >
                    <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Product Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                        />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={2} className="mb-2">
                      <Form.Label>Plant</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.category}
                        onChange={async (e) => {
                          setFieldValue("category", e.target.value);
                        }}
                        name="category"
                        className="form-control"
                        isInvalid={!!errors.category}
                      > 
                        <option value="">Select Plant</option>
                        {
                          !!categoryListData && categoryListData?.map((plant, plantindex) => <option key={plantindex} value={plant?.id}>{plant?.name}</option>)
                        }
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.category}
                      </Form.Control.Feedback>
                    </Col>
                <Col md={3} className="mb-1">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Product Image</Form.Label>
                      <Form.Control
                        id="myfile"
                        type="file"
                        name="image"
                        onChange={(event) => {
                          const varientImg = event.target.files[0];
                          setFieldValue("image", varientImg);
                        }}
                        isInvalid={!!errors.image}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={2} className="mb-2">
                      <Form.Group >
                        <Form.Label>Quantity</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter Quantity"
                            name="quantity"
                            value={values.quantity}
                            onChange={handleChange}
                            isInvalid={!!errors.quantity}
                            />
                          <Form.Control.Feedback type="invalid">
                            {errors.quantity}
                          </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  {/* <Col md={2} className="mb-2">
                      <Form.Group >
                        <Form.Label>Price</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter Product Price"
                            name="price"
                            value={values.price}
                            onChange={handleChange}
                            isInvalid={!!errors.price}
                            />
                          <Form.Control.Feedback type="invalid">
                            {errors.price}
                          </Form.Control.Feedback>
                      </Form.Group>
                    </Col> */}
                    <Col md={2} className="mb-2">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.status}
                        onChange={async (e) => {
                          setFieldValue("status", e.target.value);
                        }}
                        name="status"
                        className="form-control"
                        isInvalid={!!errors.status}
                      > 
                        <option value="">Select Status</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                      </Form.Control>
                    </Col>
              </Row>
                  <FieldArray name="variants">
                              {({ remove, push }) => (
                                <>
                                  {!!values.variants &&
                                    values.variants.map((data, index) => {
                                      return (
                                        <Row key={index} className="bg-info mb-1 pt-2 pb-2">
                                          <Col md={7}>
                                            <Row>
                                              {
                                                !!getEnableAttributeList && getEnableAttributeList?.map((attributename, itemindex) => {
                                                  return(
                                                    <Col md={3} className="mb-2" key={itemindex}>
                                                      <Form.Label>{attributename?.name}</Form.Label>
                                                      <Form.Control
                                                        as="select"
                                                        value={data?.attributes?.[attributename?.id]}
                                                        onChange={async (e) => {
                                                          setFieldValue(`variants.${index}.attributes.${attributename?.id}`, e.target.value);
                                                        }}
                                                        name={`variants.${index}.attributes.${attributename?.id}`}
                                                        className="form-control"
                                                        isInvalid={!!errors.variants}
                                                      > 
                                                        <option value="">Select Value</option>
                                                        {
                                                          attributename?.values?.map((valuesItem, itemindex) => <option key={itemindex} value={valuesItem}>{valuesItem}</option>)
                                                        }
                                                      </Form.Control>
                                                    </Col>
                                                  )
                                                })
                                              }
                                            </Row>
                                          </Col>
                                          {/* <Col md={2} sm={12}>
                                            <Form.Group>
                                              <Form.Label>
                                                Quantity
                                              </Form.Label>
                                              <Form.Control
                                                type="number"
                                                value={data?.quantity}
                                                placeholder="Enter Quantity"
                                                onChange={handleChange}
                                                name={`variants.${index}.quantity`}
                                                className="form-control bg"
                                                isInvalid={!!errors.variants}
                                              />
                                            </Form.Group>
                                          </Col> */}
                                          <Col md={2} className="mb-2">
                                              <Form.Label>Status</Form.Label>
                                              <Form.Control
                                                as="select"
                                                value={data.status}
                                                onChange={handleChange}
                                                name={`variants.${index}.status`}
                                                className="form-control"
                                                isInvalid={!!errors.variants}
                                              > 
                                                <option value="">Select Status</option>
                                                <option value={true}>Active</option>
                                                <option value={false}>Inactive</option>
                                              </Form.Control>
                                            </Col>
                                          <Col md={2} sm={12}>
                                            <Form.Group>
                                              <Form.Label>
                                                Price
                                              </Form.Label>
                                              <Form.Control
                                                type="number"
                                                value={data?.price}
                                                placeholder="₹"
                                                onChange={handleChange}
                                                name={`variants.${index}.price`}
                                                className="form-control bg"
                                                isInvalid={!!errors.variants}
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col md={1} sm={12} className="mt-4 p-0">
                                            <div className="d-flex">
                                            {(index !== 0) && (
                                              <Button
                                                variant="danger"
                                                onClick={() => remove(index)}
                                              >
                                                <i className="bi bi-trash3-fill"></i>
                                              </Button>
                                            )}
                                            <Button
                                              variant="success"
                                              onClick={() =>
                                                push({
                                                    quantity: "",
                                                    price:'',
                                                    status:''
                                                  })
                                              }
                                            >
                                              <i className="bi bi-plus-lg"></i>
                                            </Button>
                                            </div>
                                          </Col>
                                          {/* <Col md={1} sm={12} className="mt-4">
                                            {index !== 0 && (
                                              <Button
                                                variant="danger"
                                                onClick={() => remove(index)}
                                              >
                                                <i className="bi bi-trash3-fill"></i>
                                              </Button>
                                            )}
                                          </Col>
                                          <Col md={1} sm={12} className="mt-4">
                                            <Button
                                              variant="success"
                                              onClick={() =>
                                                push({
                                                    quantity: "",
                                                    price:'',
                                                    status:''
                                                  })
                                              }
                                            >
                                              <i className="bi bi-plus-lg"></i>
                                            </Button>
                                          </Col> */}
                                        </Row>
                                      );
                                    })}
                                </>
                              )}
                          </FieldArray>
              <Row>
                <Col md={12} className="mb-2">
                  <CKEditor
                        editor={ClassicEditor}
                        data={values?.description}
                        onReady={(editor) => {
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("description", data);
                          console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                <Button variant="success" className="w-100" type="submit">
                  Submit
                </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
    </>
  );
}

export default ProductAction;
