import React, { useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  getAllProducts,
  getAttributes,
  getUser,
  userUpdate,
} from "../../reducers/commonReducer";
import { Formik } from "formik";

const AssignProducts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const userId = queryParameters.get("userid");
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getAllProducts())
    dispatch(getAttributes());
    dispatch(getUser({
      id : userId
    }))
  }, []);
  const { getAllProductsData, varientsList, getAttributesList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <div className="container">
        <div className="mt-4 row">
          <div className="col-md-6 col-6 p-0">
            <h3>Products List</h3>
          </div>
          <div className="overflow-auto border mt-3">
            <Table striped hover size="sm" className="overflow-scroll">
              <thead>
                <tr>
                  <th className="col-md-1">Sr</th>
                  <th className="col-md-1">Plant</th>
                  <th className="col-md-3">Name</th>
                  <th className="col-md-8">Variant</th>
                </tr>
              </thead>
              <tbody>
                {!!getAllProductsData &&
                  getAllProductsData?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>{data?.category?.name}</td>
                        <td>{data?.name}</td>
                        <td>
                            <table>
                                <thead>
                                    <tr className="bg-info">
                                        <th className="col-md-4">Attribute</th>
                                        <th className="col-md-1 text-center">Status</th>
                                        {/* <th className="col-md-2 text-center">Price</th> */}
                                        <th className="col-md-5">Dealer Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data?.variants?.map((variant, vindex) => {
                                        return(
                                                <Fragment key={vindex}>
                                                 <tr className="border-bottom border-color-info">
                                                    <td>
                                                        {!!variant?.attributes && Object.entries(variant?.attributes).map(([key, value]) => (
                                                        <p className="mb-0" key={key}><strong>{!!getAttributesList && getAttributesList?.[key]}:</strong> {value}</p>
                                                        ))}
                                                    </td>
                                                    <td className="text-success text-center"><b className={variant?.status ? `text-success` : `text-danger`}>{variant?.status ? `Active` : `Inactive`}</b></td>
                                                    {/* <td className="text-success text-center"><b>{variant?.price}/-</b></td> */}
                                                    <td>
                                                        <TableRow itemId={variant?.id} price={444} assignprice={!!varientsList && varientsList?.[variant?.id]}/>
                                                    </td>
                                                </tr>
                                                </Fragment>
                                            )
                                        })
                                }
                                </tbody>
                            </table>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export const TableRow = (props) => {
  const { assignprice, itemId } = props;
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const userId = queryParameters.get("userid");
  return (
    <Formik
    enableReinitialize={true}
    onSubmit={async (values) => {
      const result = await dispatch(userUpdate(values));
      if(result.payload.status === 1){
        setTimeout(() => {
          dispatch(getUser({
            id: userId
          }))
        }, 500)
      }
    }}
    initialValues={{
      id:userId,
      varientPrice: [{
          id: itemId,
          price: assignprice,
        }]
    }}
  >
    {({
      handleSubmit,
      handleChange,
      values,
    }) => (
      <Form className="container" onSubmit={handleSubmit}>
        <Row>
          <Col md={8}>
          <Form.Group className="mb-3">
            <Form.Control
              type="number"
              name={`varientPrice[0].price`}
              value={values.varientPrice[0].price}
              onChange={handleChange}
            />
          </Form.Group>
          </Col>
          <Col md={4} className="p-0">
          <Button variant="success" className="w-100" type="submit">
              Submit
          </Button>
          </Col>
        </Row>
    </Form>
    )}
  </Formik>
  );
};
export default AssignProducts;
