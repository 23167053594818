import React, { useEffect } from "react";
import "./app.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import { ToastContainer } from "react-toastify";
import NotFoundPage from "./Components/NotFoundPage";
import ProductsList from "./Pages/Product/ProductList";
import UserList from "./Pages/User/List";
import Dashboard from "./Pages/Dashboard/Dashboard";
import OtpSend from "./Pages/Applogin/OtpSend";
import OTPverify from "./Pages/Applogin/OTPverify";
import CreatePassword from "./Pages/Applogin/CreatePassword";
import Settings from "./Pages/Settings/Settings";
import Applogin from "./Pages/Applogin/AppLogin";
import SignUp from "./Pages/SignUp.js/Signup";
import Add from "./Pages/User/Add";
import ForgotPassword from "./Pages/Applogin/ForgotPassword";
import OrderList from "./Pages/Order/OrderList";
import PaymentList from "./Pages/Payment/PaymentList";
import OrderAction from "./Pages/Order/OrderAction";
import PaymentAction from "./Pages/Payment/PaymentAction";
import VarientList from "./Pages/Varients/VarientList";
import ProductAction from "./Pages/Product/ProductAction";
import UserDetail from "./Pages/User/UserDetail";
import RoleList from "./Pages/Roles/RoleList";
import AttributeList from "./Pages/Attribute/AttributeList";
import CartList from "./Pages/Cart/CartList";
import ProductCard from "./Pages/Product/ProductCard";
import ReplacementWarranty from "./Pages/Replacement&Warranty/ReplacementWarranty";
import GoToTop from "./Components/GotoTop";
import Categorys from "./Pages/Category/Categorys";
import Stocklist from "./Pages/Product/Stocklist";
import AssignProducts from "./Pages/User/AssignProducts";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import { useDispatch } from "react-redux";
import { areYouLogin } from "./reducers/commonReducer";
import ContactUs from "./Pages/ContactUs";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(async() => {
    const apiResponse = await dispatch(areYouLogin())
    if(apiResponse?.payload?.status === 0){
      localStorage.clear()
    }
    if(apiResponse?.payload?.status === 1){
      if ((localStorage.getItem("userRole") === "superadmin" || 
      localStorage.getItem("userRole") === "admin" || 
      localStorage.getItem("userRole") === "accountant" || 
      localStorage.getItem("userRole") === "marketing") && localStorage.getItem("x-auth-token")) {
        return navigate(`/users`)
      }else if ((localStorage.getItem("userRole") === "manager" || 
      localStorage.getItem("userRole") === "dealer") && localStorage.getItem("x-auth-token")) {
        return navigate(`/orders`)
      } else {
        return navigate(`/`)
      }
    } 
    // else(
    //   navigate(`/`)
    // )
  },[])
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<Applogin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/category" element={<Categorys />} />
        <Route path="/roles" element={<RoleList />} />
        <Route path="/products" element={<ProductsList />} />
        <Route path="/stock" element={<Stocklist />} />
        <Route path="/products-detail" element={<ProductCard />} />
        <Route path="/products-action" element={<ProductAction />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/product-assign" element={<AssignProducts />} />
        <Route path="/payment" element={<PaymentList/>} />
        <Route path="/payment-action" element={<PaymentAction/>} />
        <Route path="/varient" element={<VarientList/>} />
        <Route path="/attribute" element={<AttributeList/>} />
        <Route path="/replacement-warranty" element={<ReplacementWarranty/>} />
        <Route path="/cart-list" element={<CartList/>} />
        <Route path="/orders" element={<OrderList />} />
        <Route path="/orders-action" element={<OrderAction />} />
        <Route path="/user" element={<UserDetail />} />
        <Route path="/add-user" element={<Add />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/sent-otp" element={<OtpSend />} />
        <Route path="/create-new-password" element={<CreatePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<OTPverify />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <GoToTop/>
    </>
  );
}
export default App;
