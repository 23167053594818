import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import moment from "moment";
import Form from "react-bootstrap/Form";
import {  getAttributes, getOrders, getUser, resetOrders } from "../../reducers/commonReducer";
import { Button } from "react-bootstrap";
import OrderDetails from "./OrderDetails";
import OrderUpdate from "./OrderUpdate";

const OrderList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const queryParameters = new URLSearchParams(window.location.search);
  const userid = queryParameters.get("userid");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [status, setStatus] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
 
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(resetOrders())
    if(userid){
      dispatch(getOrders({userid}))
    }else{
      dispatch(getOrders())
    }
    dispatch(getAttributes())
  }, []);
  const { getOrdersData, getOrderDataBlank } = useSelector((state) => state.commonReducer);
  const clear = () => {
    setStartDate("")
    setEndDate("")
    setPage(1)
    setStatus('')
    setSearch("")
    dispatch(resetOrders())
    dispatch(getOrders({
      userid : userid ? userid : ''
    }))
  }
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6} sm={6} xs={6}>
            <h3>Orders List</h3>
          </Col>
          {
           ( localStorage.getItem("userRole") === "dealer") &&  <Col md={6} sm={6} xs={6} className="text-end">
            <Link to={`/products-detail`}><Button className="float-right" variant="warning">Add Order</Button></Link>
          </Col>
          }
          <hr />
          <Col md={4}>
            <label>Search</label> 
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col md={2} sm={6} xs={6}>
            <label>Start Date</label>
            <Form.Control
              type="date"
              placeholder="Start Date"
              className="me-2"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value)
              }}
            />
          </Col>
          <Col md={2} sm={6} xs={6}>
            <label>End Date</label>
            <Form.Control
              type="date"
              placeholder="End Date"
              className="me-2"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
          </Col>
          <Col md={2} sm={6} xs={6}>
            <label>Status</label>
              <Form.Control
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                    as="select"
                    name="status"
                    className="form-control"> 
                    <option value="">Select Status</option>
                    <option value={"Pending"}>Pending</option>
                    <option value={"Processing"}>Processing</option>
                    <option value={"Completed"}>Completed</option>
            </Form.Control>
          </Col>
          <Col md={1} sm={3} xs={3}>
            <Button 
            onClick={() => {
              dispatch(resetOrders())
              dispatch(getOrders({
                userid : userid ? userid : '',
                startDate,
                endDate,
                status, search
              }));
            }}
            variant="success" className="mt-4"><i className="bi bi-search"></i></Button>
          </Col>
          <Col md={1} sm={3} xs={3}>
            <Button variant="danger" onClick={() => clear()} className="mt-4">Clear</Button>
          </Col>
        </Row>

        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>OrderID</th>
                <th>Date</th>
                <th>Dealer Details</th>
                <th>Product</th>
                <th>Total Qty</th>
                {(localStorage.getItem("userRole") !== "manager") && <th>Total</th>}
                <th>Status</th>
                <th>Deliver Address</th>
                <th>Serial Numbers</th>
                {
                  (localStorage.getItem("userRole") !== "dealer") && <th>Action</th>
                }
              </tr>
            </thead>
            <tbody>
              {!!getOrdersData
                ? getOrdersData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="OrderID">{index + 1} <br/>{data?.orderid}</td>
                        <td data-label="Date">{moment(data?.createdAt).format("LL")}</td>
                        <td data-label="Dealer Details">
                          <b>Name:- </b>{data?.user?.name} ({data?.user?.username})<br/>
                          <b>Phone:- </b>{data?.user?.phone}<br/>
                          <b>Address:- </b>{`${data?.user?.address}, ${data?.user?.city}, ${data?.user?.state}`}<br/>
                        </td>
                        <td data-label="Product">
                          <OrderDetails title="View" heading={data?.name} variants={data?.items}/>
                        </td>
                        <td data-label="Total Qty">{data?.quantity}</td>
                        {(localStorage.getItem("userRole") !== "manager") && <td data-label="Total">{data?.amount}/-</td>}
                        <td data-label="Status">
                          <b>{
                              data?.status === "Pending" && <span className="text-primary">Pending</span> ||
                              data?.status === "Processing" && <span className="text-warning">Processing</span> ||
                              data?.status === "Completed" && <span className="text-success">Completed</span> ||
                              data?.status === "Cancel" && <span className="text-danger">Cancel</span>
                            }</b>
                        </td>
                        <td data-label="Deliver Address">
                          {data?.address && `${data?.address},`} {data?.city && `${data?.city} - `}{data?.pincode && `${data?.pincode},`} {data?.state && `${data?.state}`}<br/>
                          <span><b>Vehicle No:</b> {data?.vehicleNo}</span><br/>
                          <span><b>Transport Name:</b> {data?.transportName}</span>
                          <span><b>Driver No:</b> {data?.driverNo}</span>
                        </td>
                        <td data-label="Serial Numbers">{data?.serialnumbers?.map((serial, sindex) => <span key={sindex}>{serial}, </span>)}</td>
                       {
                        ((localStorage.getItem("userRole") !== "dealer") && (data?.status !== "Cancel")) && <td data-label="Action">
                        <OrderUpdate title={"Update"} status={data?.status} id={data?.id} serialnumbers={data?.serialnumbers}
                        address={data?.address} state={data?.state} city={data?.city} pincode={data?.pincode} vehicleNo={data?.vehicleNo} transportName={data?.transportName}
                        driverNo={data?.driverNo}/>
                      </td>
                       } 
                      </tr>
                    );
                  })
                : "No record found"}
            </tbody>
          </Table>
        </div>
        <Row className="mt-2">
          <Col className="p-0">
            {!!getOrderDataBlank && getOrderDataBlank.length > 0 ? (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getOrders({
                      page: page + 1,
                      startDate, endDate, status,
                      userid : userid ? userid : ''
                    })
                  );
                }}
              >
                Load More
              </Button>
            ) : (
              <p className="text-center">
                <b>No record found 😔.</b>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OrderList;
