import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import {
  getUser
} from "../../reducers/commonReducer";
import { Row,Col, Button } from "react-bootstrap";
import imgborder from "../../images/border.png"

const UserDetail = () => {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const userId = queryParameters.get("userid");
  useEffect(() => {
    if(userId){
    dispatch(getUser({
      id:userId
    }))}
  },[])
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const { getUserDetails } = useSelector((state) => state.commonReducer);
  return (
    <>
    <Header/>
      <div className="container mt-4">
        <Row>
            <Col className="box-shadow mb-3 p-4">
            <div className="text-center mb-4">
            <h3 className="mb-0">Dealer Details</h3>
            <img src={imgborder}/>
            </div>
            <p>Name: <b>{getUserDetails?.name}</b></p>
            <p>Email: <b>{getUserDetails?.email}</b></p>
            <p>Phone: <b>{getUserDetails?.phone}</b></p>
            <p>Address: <b>{`${getUserDetails?.address}, ${getUserDetails?.city}-${getUserDetails?.zipcode}, ${getUserDetails?.state}`}</b></p>
            </Col>
        </Row>
        <Row>
            <Col className="box-shadow mb-3 p-4">
            <div className="text-center mb-4">
            <h3 className="mb-0">Firm Details</h3>
            <img src={imgborder}/>
            </div>
            <p>FirmName: <b>{getUserDetails?.firmname}</b></p>
            <p>Alternative Phone No.: <b>{getUserDetails?.secondPhone}</b></p>
            <p>GST: <b>{getUserDetails?.gstNo}</b> <Button onClick={() =>{
                openInNewTab(`/${getUserDetails?.gst}`)
            }}>View GST</Button></p>
            <p>Udyam: <b>{getUserDetails?.udyamnumber}</b> <Button
            onClick={() =>{
                openInNewTab(`/${getUserDetails?.gst}`)
            }}>View Udyam</Button></p>
            <p>AadharNo: <b>{getUserDetails?.aadharNo}</b> <Button
            onClick={() =>{
                openInNewTab(`/${getUserDetails?.gst}`)
            }}>View Aadhar</Button></p>
            </Col>
        </Row>
        <Row>
            <Col className="box-shadow mb-3 p-4">
            <div className="text-center mb-4">
            <h3 className="mb-0">Bank Details</h3>
            <img src={imgborder}/>
            </div>
            <p>Bank Name: <b>{getUserDetails?.bank?.bankName}</b></p>
            <p>IFSC: <b>{getUserDetails?.bank?.ifsc}</b></p>
            <p>Account Number: <b>{getUserDetails?.bank?.acountNo}</b></p>
            <p>Cheque: <b><Button
            onClick={() =>{
                openInNewTab(`/${getUserDetails?.aadhar}`)
            }}>View Cheque</Button></b></p>
            <p>Account Holder Name: <b>{getUserDetails?.bank?.acountHolder}</b></p>
            <p>Alternative Phone No.: <b>{getUserDetails?.bank?.bankName}</b></p>
            </Col>
        </Row>
      </div>
    </>
  );
};

export default UserDetail;
