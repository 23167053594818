import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import Header from "../../Components/Header";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addPayment, updatePayment} from "../../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import payment from '../../images/payment.jpg';
import moment from "moment";

const schema = yup.object().shape({
  amount: yup.string().required(),
});

function PaymentAction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const userid = queryParameters.get("userid");
  const paymentid = queryParameters.get("paymentid");
  const amount = queryParameters.get("amount");
  const comment = queryParameters.get("comment");
  const date = queryParameters.get("date");
  return (
    <>
      <Header/>
      <Row className="align-center">
        <Col md={6}>
          <img className="w-100" src={payment}/>
        </Col>
        <Col md={6} className="p-5">
        <Formik
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async(values, { resetForm }) => {
           const result = paymentid ? await dispatch(updatePayment(values)) : await dispatch(addPayment(values));
           if(result?.payload?.status){
            setTimeout(() => {
              navigate(`/payment?userid=${userid}`)
            }, 1000)
           }
            resetForm({ values: "" });
          }}
          initialValues={{
            id:paymentid,
            user:userid,
            amount: +amount,
            comment:comment,
            date: moment(date).format('YYYY-MM-DD')
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Amount"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      isInvalid={!!errors.amount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.amount}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter Date"
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      isInvalid={!!errors.date}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.date}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group >
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Comment"
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      isInvalid={!!errors.comment}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.comment}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
        </Col>
      </Row>
    </>
  );
}

export default PaymentAction;
