import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getPayments, resetPayments } from "../../reducers/commonReducer";
import moment from "moment";

const PaymentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const userId = queryParameters.get("userid");
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(resetPayments())
    dispatch(getPayments({
      user:userId ? userId : ''
    }));
  }, []);
  const { getPaymentsList, getPaymentDataBlank } = useSelector((state) => state.commonReducer);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [type, setType] = useState();
  const clear = () => {
    setStartDate("")
    setEndDate("")
    setPage(1)
    setType('')
    dispatch(resetPayments())
    dispatch(getPayments({
      user : userId ? userId : ''
    }))
  }
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3>Transaction List</h3>
          </Col>
          {
            userId && <Col md={6}>
            <Link className="float-end" to={`/payment-action?userid=${userId}`}><Button>Add Payment</Button></Link>
          </Col>
          }
          <hr />
          <Col md={4} sm={6} xs={6}>
            <label>Start Date</label>
            <Form.Control
              type="date"
              placeholder="Start Date"
              className="me-2"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value)
              }}
            />
          </Col>
          <Col md={4} sm={6} xs={6}>
            <label>End Date</label>
            <Form.Control
              type="date"
              placeholder="End Date"
              className="me-2"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
          </Col>
          <Col md={2} sm={6} xs={6}>
            <label>Type</label>
              <Form.Control
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                    as="select"
                    name="Type"
                    className="form-control"> 
                    <option value="">Select Type</option>
                    <option value={"ADD"}>ADD</option>
                    <option value={"SUBTRACT"}>SUBTRACT</option>
            </Form.Control>
          </Col>
          <Col md={1} sm={3} xs={3}>
            <Button 
            onClick={() => {
              dispatch(resetPayments())
              dispatch(getPayments({
                user:userId,
                startDate,
                endDate,
                type
              }));
            }}
            variant="success" className="mt-4"><i className="bi bi-search"></i></Button>
          </Col>
          <Col md={1} sm={3} xs={3}>
            <Button variant="danger" onClick={() => clear()} className="mt-4">Clear</Button>
          </Col>
        </Row>

        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Name</th>
                <th>Credit</th>
                <th>Debit</th>
                <th>Remaining Amount</th>
                <th>Date</th>
                <th>Type</th>
                <th>Comment</th>
                {userId && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {!!getPaymentsList
                ? getPaymentsList?.map((data, index) => {
                    return (
                      <tr key={data.id}>
                        <td data-label="Sr">{index + 1}.</td>
                        <td data-label="Name"><span className={data?.type === "ADD" ? "text-success" : "text-danger"}><b>{data?.fromUser ? data?.fromUser?.name : data?.toUser?.name}</b></span></td>
                        <td data-label="Credit"><b className={data?.type === "ADD" ? "text-success" : ''}>{data?.type === "ADD" && `${data?.amount}/-`}</b></td>
                        <td data-label="Debit"><b className={data?.type === "SUBTRACT" ? "text-danger" : ''}>{data?.type === "SUBTRACT" && `${data?.amount}/-`}</b></td>
                        <td data-label="Remaining Amount"><b>{`${data?.remaining}/-`}</b></td>
                        <td data-label="Date">{moment(data?.date ? data?.date : data?.createdAt).format("MMM Do YY")}</td>
                        <td data-label="Type"><span className={data?.type === "ADD" ? "text-success" : "text-danger"}>{data?.type}</span></td>
                        <td data-label="Comment">{data?.comment}</td>
                        {(userId && data?.type === "ADD" && moment(data?.createdAt).isAfter(moment().subtract(24, 'hours'))) && <td data-label="Action"><Link to={`/payment-action?userid=${userId}&amount=${data?.amount}&comment=${data?.comment}&paymentid=${data?.id}&date=${data?.date ? data?.date : data?.createdAt}`}><Button variant="info">Update Payment</Button></Link></td>}
                      </tr>
                    );
                  })
                : "No record found"}
            </tbody>
          </Table>
        </div>
        <Row className="mt-2">
          <Col className="p-0">
            {!!getPaymentDataBlank && getPaymentDataBlank.length > 0 ? (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(getPayments({
                    user:userId ? userId : '',
                    page: page + 1,
                      startDate, endDate, type
                  }))
                }}
              >
                Load More
              </Button>
            ) : (
              <p className="text-center">
                <b>No record found 😔.</b>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PaymentList;
