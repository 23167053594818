import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  postApi,
  messages,
  postApiFile,
  fileWithObjArray,
  productFileWithArray,
} from "../helpers/apiStructure";

var baseUrl = "http://localhost:3000";
// In case instead of localhost
if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}

//dashboard
export const getDashboard = createAsyncThunk("getDashboard", async (body) => {
  const result = await postApi(`${baseUrl}/api/dashboard`, "get", body);
  return result;
});
//role
export const getUserRole = createAsyncThunk("getUserRole", async (body) => {
  const result = await postApi(`${baseUrl}/api/roles`, "post", body);
  return result;
});
//state
export const getStates = createAsyncThunk("getStates", async (body) => {
  const result = await postApi(`${baseUrl}/api/states`, "post", body);
  return result;
});
//users
export const resetUsers = createAsyncThunk("resetUsers", async (data) => {
  return [];
});
export const getUsers = createAsyncThunk("getUsers", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/main`, "post", body);
  return result;
});
export const getUser = createAsyncThunk("getUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/profile`, "post", body);
  return result;
});
export const userUpdate = createAsyncThunk("userUpdate", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/update/${body?.id}`, "put", body);
  await messages(result?.message, result?.status);
  return result;
});
export const addUser = createAsyncThunk("addUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateUser = createAsyncThunk("updateUser", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/user/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const userDelete = createAsyncThunk("userDelete", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/delete/${body?.id}`, "delete", body);
  await messages(result?.message, result?.status);
  return result;
});
export const areYouLogin = createAsyncThunk("areYouLogin", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/verify`, "post", body);
  return result;
});
// Products Mgt
export const getProducts = createAsyncThunk("getProducts", async (body) => {
  const result = await postApi(`${baseUrl}/api/product/list`, "post", body);
  return result;
});
export const addProduct = createAsyncThunk("addProduct", async (body) => {
  const result = await productFileWithArray(`${baseUrl}/api/product/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const singleProduct = createAsyncThunk("singleProduct", async (body) => {
  const result = await postApi(`${baseUrl}/api/getproduct`, "post", body);
  return result;
});
export const updateProduct = createAsyncThunk("updateProduct", async (body) => {
  const result = await productFileWithArray(
    `${baseUrl}/api/product/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const getAllProducts = createAsyncThunk(
  "getAllProducts",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/products/main`, "post", body);
    return result;
  }
);
// Varient
export const getVarient = createAsyncThunk("getVarient", async (body) => {
  const result = await postApi(`${baseUrl}/api/variants/main`, "post", body);
  return result;
});
export const getEnableVarient = createAsyncThunk("getEnableVarient", async (body) => {
  const result = await postApi(`${baseUrl}/api/variants`, "post", body);
  return result;
});
export const getVarientWithoutPage = createAsyncThunk("getVarientWithoutPage", async (body) => {
  const result = await postApi(`${baseUrl}/api/variant/list`, "get");
  return result;
});
export const addVarient = createAsyncThunk("addVarient", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/variant/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateVarient = createAsyncThunk("updateVarient", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/variant/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// Attribute
export const getMainAttribute = createAsyncThunk("getMainAttribute", async (body) => {
  const result = await postApi(`${baseUrl}/api/attribute/mainlist`, "post", body);
  return result;
});
export const getEnableAttribute = createAsyncThunk("getEnableAttribute", async (body) => {
  const result = await postApi(`${baseUrl}/api/attribute/list`, "post", body);
  return result;
});
export const addAttribute = createAsyncThunk("addAttribute", async (body) => {
  const result = await postApi(`${baseUrl}/api/attribute/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateAttribute = createAsyncThunk("updateAttribute", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/attribute/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const getAttributes = createAsyncThunk("getAttributes", async (body) => {
  const result = await postApi(`${baseUrl}/api/attributes`, "get");
  return result;
});
//Cart
export const getCartList = createAsyncThunk("getCartList", async (body) => {
  const result = await postApi(`${baseUrl}/api/cartlist`, "get");
  return result;
});
export const addCart = createAsyncThunk("addCart", async (body) => {
  const result = await postApi(`${baseUrl}/api/cart/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateCart = createAsyncThunk("updateCart", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/cart/edit/${body?.id}`, "put", body);
  await messages(result?.message, result?.status);
  return result;
});
//Order Mgt
export const resetOrders = createAsyncThunk("resetOrders", async (data) => {
  return [];
});
export const getOrders = createAsyncThunk("getOrders", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/list`, "post", body);
  return result;
});
export const getOrder = createAsyncThunk("getOrder", async (body) => {
  const result = await postApi(`${baseUrl}/api/getorder`, "post", body);
  return result;
});
export const addOrder = createAsyncThunk("addOrder", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateOrder = createAsyncThunk("updateOrder", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//Payment Mgt (transactions)
export const resetPayments = createAsyncThunk("resetPayments", async (data) => {
  return [];
});
export const getPayments = createAsyncThunk("getPayments", async (body) => {
  const result = await postApi(`${baseUrl}/api/transaction/list`, "post", body);
  return result;
});
export const getPayment = createAsyncThunk("getPayment", async (body) => {
  const result = await postApi(`${baseUrl}/api/transaction/details`, "post", body);
  return result;
});
export const addPayment = createAsyncThunk("addPayment", async (body) => {
  const result = await postApi(`${baseUrl}/api/transaction/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updatePayment = createAsyncThunk("updatePayment", async (body) => {
  const result = await postApi(`${baseUrl}/api/transaction/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//send otp
export const sendOtp = createAsyncThunk("sendOtp", async (body) => {
  const result = await postApi(`${baseUrl}/api/otpSend`, "post", body);
  result?.status === 1 && localStorage.setItem("otpPhone", body?.phone);
  await messages(result?.message, result?.status);
  return result;
});
// Login
export const adminLogin = createAsyncThunk("adminLogin", async (body) => {
  const result = await postApi(`${baseUrl}/auth/login`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const verifyOtp = createAsyncThunk("verifyOtp", async (body) => {
  const result = await postApi(`${baseUrl}/api/otpVerify`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const createNewPassword = createAsyncThunk(
  "createNewPassword",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/user/forgetPassword`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const userLogOut = createAsyncThunk("userLogOut", async (body) => {
  const result = await postApi(`${baseUrl}/auth/logout`, "get", body);
  return result;
});
export const userSignup = createAsyncThunk("userSignup", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/user/signup`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//user Profile
export const userProfile = createAsyncThunk("userProfile", async (body) => {
  const result = await postApi(`${baseUrl}/api/getuser`, "get", body);
  return result;
});
export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (body) => {
    const result = await fileWithObjArray(
      `${baseUrl}/api/user/updateprofile`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//Categorys
export const addCategory = createAsyncThunk("addCategory", async (body) => {
  const result = await postApi(`${baseUrl}/api/category/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const categoryList = createAsyncThunk("categoryList", async (body) => {
  const result = await postApi(`${baseUrl}/api/category/list`, "post", body);
  return result;
});
//Replacement
export const addReplacement = createAsyncThunk("addReplacement", async (body) => {
  const result = await postApi(`${baseUrl}/api/replacement/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateReplacement = createAsyncThunk("updateReplacement", async (body) => {
  const result = await postApi(`${baseUrl}/api/replacement/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const replacementList = createAsyncThunk("replacementList", async (body) => {
  const result = await postApi(`${baseUrl}/api/replacement/list`, "post", body);
  return result;
});
// settings
export const getSettings = createAsyncThunk("getSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/setting/list`, "post", body);
  return result;
});
export const addSettings = createAsyncThunk("addSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/setting/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateSetting = createAsyncThunk("updateSetting", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/setting/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});



const userReducer = createReducer(
  {},
  (builder) => {
    builder
      // Login
      .addCase(adminLogin.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          localStorage.setItem("x-auth-token", action.payload.token);
          localStorage.setItem("userRole", action.payload?.me?.role?.slug);
          localStorage.setItem("userid", action.payload?.me?.id);
        }
      })
      // Dashboard
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.getDashboardData = action.payload;
      })
      //Role 
      .addCase(getUserRole.fulfilled, (state, action) => {
        state.getUserRoleList = action.payload?.list;
      })
      //state
      .addCase(getStates.fulfilled, (state, action) => {
        state.getStatesList = action.payload?.allStates;
      })
      //reset Users
      .addCase(resetUsers.fulfilled, (state, action) => {
        state.getUsersData = [];
      })
      // User list
      .addCase(getUsers.fulfilled, (state, action) => {
        if (!Array.isArray(state.getUsersData)) {
            state.getUsersData = [];
          }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getUsersData = [...state.getUsersData, ...newUserList];
        state.getUsersDataBlank = action.payload.list;
        state.userCount = action.payload?.count
      })
       // Products list
      .addCase(getProducts.fulfilled, (state, action) => {
        state.getProductsData = action.payload?.list;
      })
      .addCase(singleProduct.fulfilled, (state, action) => {
        state.singleProductData = action.payload?.product;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.getAllProductsData = action.payload?.list;
      })
      //Varient 
        .addCase(getVarient.fulfilled, (state, action) => {
          state.getVarientList = action.payload?.list;
        })
        .addCase(getEnableVarient.fulfilled, (state, action) => {
          state.getEnableVarientList = action.payload?.list;
        })
        .addCase(getVarientWithoutPage.fulfilled, (state, action) => {
          state.getVarientWithoutPageList = action.payload?.list;
        })
      //Attribute 
      .addCase(getMainAttribute.fulfilled, (state, action) => {
        state.getAttributeList = action.payload?.list;
      })
      .addCase(getEnableAttribute.fulfilled, (state, action) => {
        state.getEnableAttributeList = action.payload?.list;
      })
      .addCase(getAttributes.fulfilled, (state, action) => {
        state.getAttributesList = action.payload?.list;
      })
      //cart 
      .addCase(getCartList.fulfilled, (state, action) => {
        state.cartListData = action.payload?.list;
        state.cartListCount = action.payload?.count;
      })
      //user Profile
      .addCase(getUser.fulfilled, (state, action) => {
        state.getUserDetails = action.payload?.user;
        state.varientsList = action.payload?.varients;
      })
      .addCase(userProfile.fulfilled, (state, action) => {
        state.userProfileData = action.payload?.user;
        state.userSelectProduct = action.payload?.selectedProducts;
      })
      //orders
      .addCase(resetOrders.fulfilled, (state, action) => {
        state.getOrdersData = [];
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        if (!Array.isArray(state.getOrdersData)) {
            state.getOrdersData = [];
          }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getOrdersData = [...state.getOrdersData, ...newUserList];
        state.getOrderDataBlank = action.payload.list;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.getOrderDetails = action.payload?.order;
      })
      //payments
      .addCase(resetPayments.fulfilled, (state, action) => {
        state.getPaymentsList = [];
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        if (!Array.isArray(state.getPaymentsList)) {
            state.getPaymentsList = [];
          }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getPaymentsList = [...state.getPaymentsList, ...newUserList];
        state.getPaymentDataBlank = action.payload.list;
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.getPaymentDetails = action.payload?.details;
      })
      //replacement
      .addCase(replacementList.fulfilled, (state, action) => {
        state.replacementDataList = action.payload?.list;
      })
      //categorys
      .addCase(categoryList.fulfilled, (state, action) => {
        state.categoryListData = action.payload?.list;
      })
      //signup
      .addCase(userSignup.fulfilled, (state, action) => {
        action.payload.status === 1 &&
          localStorage.setItem("userSignupStatus", action.payload.status);
      })
      //settings
      .addCase(getSettings.fulfilled, (state, action) => {
        state.getSettingsList = action.payload?.list;
      })
  }
);

export default userReducer;
