import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {  getMainAttribute } from "../../reducers/commonReducer";
import TagsInput from "../TagsInput";

const schema = yup.object().shape({
  name: yup.string().required(),
});

function AttributeAction(props) {
  const {
    title,
    name,
    description,
    id,
    valuesList,
    btnTitle,
    api,status
  } = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [tagValues, setTagValues] = useState(valuesList ? valuesList : []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant={btnTitle === "Add" ? "success" : "primary"} onClick={handleShow}>
        {btnTitle}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(api({name:values.name, id:values.id, description:values.description, status:values.status, values:tagValues}));
            resetForm({ values: "" });
            dispatch(getMainAttribute())
            setShow(false)
          }}
          initialValues={{
            name,
            description,
            id,
            status
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-2">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        value={values.status}
                        onChange={async (e) => {
                          setFieldValue("status", e.target.value);
                        }}
                        name="status"
                        className="form-control"
                        isInvalid={!!errors.status}
                      > 
                        <option value="">Select Status</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                      </Form.Control>
                    </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                <Form.Label>Values (Enter Like:- Red, Blue,)</Form.Label>
                <TagsInput tagValues={tagValues} setTagValues={setTagValues} values={valuesList}/>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                <Form.Group >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                      as="textarea" rows={3}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>  
                </Col>
              </Row>
              {/* <Row className="mb-3">
                <Col md={12}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  > 
                    <option value="">Select Status</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Form.Control>
                </Col>
              </Row> */}
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default AttributeAction;
