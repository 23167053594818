import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SABKAMUNAFA from "../images/logo.png";

export default function ContactUs() {
  return (
    <Container>
         <Row>
            <Col md={12} className='mt-2'>
                <div className="form-logo text-center">
                <img src={SABKAMUNAFA} />
                </div>
            </Col>
        </Row>
        <Row className='mt-4'>
            <Col md={12}>
                <h1>Contact Us</h1>
                <hr/>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <h4>Address</h4>
                <p>O.P.Jindal Marg, Near Unique Motors (Maruti Showroom), Hisar Haryana - 125006</p>
                <h4>Phone Number</h4>
                <p>+91 7404599121</p>
                <h4>E-Mail</h4>
                <p>info@ereganto.in</p>
                <h4>Working time:</h4>
                <p className='mb-0'>Mon-Sat: 9:00am - 6:00pm</p>
                <p>Sunday is closed</p>
            </Col>
            <Col md={6}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3485.3798651934458!2d75.79179887551898!3d29.123984175399986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391233f7456211e9%3A0x5ecdd4381fc00724!2sUnique%20Motors%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1710306418606!5m2!1sen!2sin" width="100%" height="400" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </Col>
        </Row>
    </Container>
  )
}
