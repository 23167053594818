import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { categoryList, getAllProducts, getAttributes } from "../../reducers/commonReducer";

const Stocklist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getAllProducts());
    dispatch(categoryList());
    dispatch(getAttributes());
  }, []);
  const { getAllProductsData, getAttributesList, categoryListData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={12}>
            <h3>Stock</h3>
          </Col>
          <hr />
          <Col md={6} className="mb-2">
            <Form.Control
                as="select"
                onChange={async (e) => {
                    dispatch(getAllProducts({
                        category: e.target.value
                    }))
                }}
                name="category"
                className="form-control"
            > 
                <option value="">Select Plant</option>
                {
                    !!categoryListData && categoryListData?.map((plant) => <option value={plant?.id}>{plant?.name}</option>)
                }
            </Form.Control>
          </Col>
        </Row>

        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th className="col-md-2">Sr</th>
                <th className="col-md-6">Product Name</th>
                <th className="col-md-4">Variant</th>
                <th className="col-md-6">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {!!getAllProductsData
                ? getAllProductsData?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="Sr">{index + 1}.</td>
                        <td data-label="Product Name">{data?.name}</td>
                        <td data-label="Variant">
                            <Table>
                                <tbody>
                                    {data?.variants?.map((variant, vindex) => {
                                        return(
                                                <tr key={vindex}>
                                                    <td>
                                                        {!!variant?.attributes && Object.entries(variant?.attributes).map(([key, value]) => (
                                                        <p className="mb-0" key={key}><strong>{!!getAttributesList && getAttributesList?.[key]}:</strong> {value}</p>
                                                        ))}
                                                    </td>
                                                </tr>
                                                )
                                                })
                                    }
                                </tbody>
                            </Table>
                            </td>
                            <td data-label="Quantity">{data?.quantity}</td>
                      </tr>
                    );
                  })
                : "No record found"}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Stocklist;
