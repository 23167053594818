import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { addOrder, getAttributes, getCartList, getUser, updateCart } from "../../reducers/commonReducer";

const CartList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const userId = queryParameters.get("userid");
  const width = useRef(window.innerWidth);
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getAttributes())
  }, []);
  const { cartListData, varientsList, getAttributesList } = useSelector((state) => state.commonReducer);
  var amountSum = 0;
  var totalQty = 0;
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={12}>
            <h3>Cart List</h3>
          </Col>
          {
            userId && <Col md={6}>
            <Link to={`/payment-action?userid=${userId}`}><Button>Add Payment</Button></Link>
          </Col>
          }
        </Row>

<div className="overflow-auto">
  <Table hover size="sm" className="custom-table">
    <thead>
      <tr>
        <th>Sr</th>
        <th>Product Name</th>
        <th>Variant</th>
        <th>Color</th>
        <th>Qty</th>
        <th>Unit Price</th>
        <th>Total</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {!!cartListData
        ? cartListData?.map((data, index) => {
          amountSum += ((varientsList?.[data?.varient?.id] ? varientsList?.[data?.varient?.id] : data?.varient?.price) * data?.quantity)
          totalQty += data?.quantity
          return (
            <tr key={data.id}>
              <td data-label="Sr">{index + 1}.</td>
              <td data-label="Product Name" className={`${width.current > 500 ? `cart-name` : '' }`}>{data?.varient?.product?.name}</td>
              <td data-label="Variant">
                {!!data?.varient?.attributes && Object.entries(data?.varient?.attributes).map(([key, value]) => (
                  <Col key={key} className={`${width.current > 500 ? `d-flex ` : '' }g-2`}>
                    {!!getAttributesList && getAttributesList?.[key]} : <strong className="class-value">{value}</strong>
                  </Col>
                ))}
              </td>
              <td data-label="Color">{data?.color}</td>
              <td data-label="Qty">
                <Row className="align-center">
                  <Col md={3}>
                    <Button variant="outline-danger" onClick={async () => {
                      await dispatch(updateCart({
                        id: data?.id,
                        quantity: data?.quantity - 1
                      }))
                      await dispatch(getCartList())
                    }}>-</Button>
                  </Col>
                  <Col md={3} className={width.current > 500 ? "text-center" : ''}><b className={width.current > 500 ? "" : 'px-3'}>{data?.quantity}</b></Col>
                  <Col md={3}>
                    <Button variant="outline-success" onClick={async () => {
                      await dispatch(updateCart({
                        id: data?.id,
                        quantity: data?.quantity + 1
                      }))
                      await dispatch(getCartList())
                    }}>+</Button>
                  </Col>
                </Row>
              </td>
              <td data-label="Unit Price"><span className={varientsList?.[data?.varient?.id] && "text-success"}>{varientsList?.[data?.varient?.id]}/-</span></td>
              <td data-label="Total"><b>{(varientsList?.[data?.varient?.id] ? varientsList?.[data?.varient?.id] : data?.varient?.price) * data?.quantity}/-</b></td>
              <td data-label="Action">
                <Button variant="outline-danger" onClick={async () => {
                  await dispatch(updateCart({
                    id: data?.id,
                    quantity: 0
                  }))
                  await dispatch(getCartList())
                }}><i className="bi bi-trash3-fill"></i></Button>
              </td>
            </tr>
          );
        })
        : "No record found"}
    </tbody>
  </Table>    

  <div className="container-fluid">
  <Row>
    <Col md={8}> 
    </Col>
    <Col md={4}>
    <Row className="text-center">
    <h2 className="mb-0"><b>Total</b></h2>
    </Row>
    <hr/>
    <Row> 
      <Col md={6} sm={6} xs={6}> 
      <h4>Total Quantity </h4>
      </Col> 
      <Col md={6} sm={6} xs={6} className="text-end">  
      <h4>{totalQty}</h4>
      </Col>
    </Row> 
  <Row> 
    <Col md={6} sm={6} xs={6}> 
      <h4>Total Price </h4>
    </Col> 
    <Col md={6} sm={6} xs={6} className="text-end">  
      <h4>{amountSum} </h4> 
    </Col>
    <hr/>
  </Row>  
  <Row className="mb-5">   
  <Button className="w-100 custom-place-order-button" variant="success" onClick={async () => {
    const apiresult = await dispatch(addOrder())
    if (apiresult?.payload?.status === 1) {
      return setTimeout(() => {
        navigate("/orders");
      }, 1000);
    }
    }}>Place Order</Button>
  </Row>
  </Col>
  </Row>
  </div>
  </div>
  </div>
    </>
  );
};

export default CartList;
