import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getUserRole} from "../../reducers/commonReducer";
import red from "../../images/red.png";
import green from "../../images/green.png";

const RoleList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getUserRole());
  }, []);
  const { getUserRoleList } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Roles</h3>
          </Col>  
          <hr/>
        </Row>
        <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Name</th>
              <th>Slug</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {!!getUserRoleList &&
              getUserRoleList.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>{data?.name}</td>
                    <td>{data?.slug}</td>
                    <td className="table-img">
                      {data?.status === true ? <img src={green} /> : <img src={red} />}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table> 
        </div>
      </div>
    </>
  );
};

export default RoleList;
