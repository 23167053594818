import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { addAttribute,  getMainAttribute, updateAttribute } from "../../reducers/commonReducer";
import AttributeAction from "./AttributeAction";

const AttributeList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getMainAttribute())
  }, []);
  const { getAttributeList } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
          <Col md={6} sm={6} xs={6}>
          <h3>Attribute</h3>
          </Col>  
          <Col md={6} sm={6} xs={6} className="text-end">
            <AttributeAction
             title="Add Attribute"
             btnTitle="Add"
             api={addAttribute}
             />
          </Col>
        </Row>
        <hr/>
        <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Name</th>
              <th>Values</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getAttributeList &&
              getAttributeList?.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td data-label="Sr">{index + 1}.</td>
                    <td data-label="Name">{data?.name}</td>
                    <td data-label="Values">{data?.values?.map((item) => `${item}, `)}</td>
                    <td data-label="Description">{data?.description}</td>
                    <td data-label="Status">
                      {data?.status === true ? 
                       <b className="text-success">Active</b> : <b className="text-danger">Inactive</b>}
                    </td>
                    <td data-label="Action">
                      <AttributeAction
                        title="Update Attribute"
                        btnTitle="Edit"
                        name={data?.name}
                        description={data?.description}
                        valuesList={data?.values}
                        status = {data?.status}
                        id={data?.id}
                        api={updateAttribute}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table> 
        </div>
      </div>
    </>
  );
};

export default AttributeList;
