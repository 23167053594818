import React, { useEffect, useRef } from "react";
import { Col, Button, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import * as yup from "yup";
import logo from "../../images/logo.png";
import imgborder from "../../images/border.png"
import signupimg from '../../images/signup.jpg' 
import {
  getUser,
  userSignup,
  userUpdate,
} from "../../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required("Please Enter Proprietor/Partner Name"),
  email: yup.string().email("Invalid email address").required(),
  address: yup.string().required("Please Enter Address."),
  city: yup.string().required("Please Enter city."),
  state: yup.string().required("Please Enter state."),
  zipcode: yup.string().required("Please Enter PIN code."),
  firmname: yup.string().required("Please Enter Firmname."),
  password: yup.string().required("Please Enter Password."),
  phone: yup.string()
  .notOneOf([yup.ref('secondPhone')], 'Phone numbers must be different')
  .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
  .required('Phone number is required'),
  secondPhone: yup.string()
  .notOneOf([yup.ref('phone')], 'Phone numbers must be different')
  .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
  .required('Alternative Phone No. is required'),
  gstNo: yup.string().required("Please Enter GST No."),
  aadharNo: yup.string().required("Please Enter Aadhar No."),
  gst: yup.mixed().required("Please Upload GST"),
  aadhar: yup.mixed().required("Please Upload Aadhar"),
});

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useRef(window.innerWidth);
  const queryParameters = new URLSearchParams(window.location.search);
  const userId = queryParameters.get("userid");
  const api = async (values) => {
    const apiResponse = ((userId || localStorage.getItem("userRole") === "dealer") ? await dispatch(userUpdate(values)) : await dispatch(userSignup(values)));
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        ((localStorage.getItem("userRole") === "admin") || localStorage.getItem("userRole") === "manager" || localStorage.getItem("userRole") === "superadmin") ? navigate("/users") : navigate("/");
      }, 1000);
    }
    return null;
  };
  useEffect(() => {
    if(userId || localStorage.getItem("userRole") === "dealer"){
    dispatch(getUser({
      id:userId
    }))}
  },[])
  const { getUserDetails } = useSelector((state) => state.commonReducer);
  return (
    <>
    {
      localStorage.getItem("x-auth-token") && <Header/>
    }
      <div className="content sign-up">
        <Row className="sign-up">
          <Col md={7} className="login-form p-4">
            <div className="text-center mt-2">
              <img src={logo} alt="logo" />
            </div>
            <hr/>
            <div className="text-center">
              <p>
                {(userId || localStorage.getItem("userRole") === "dealer") ? <b>Profile Update</b> : <b> Sign Up</b>}
              </p>
            </div>

            <Formik
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={(values) => {
                dispatch(api(values));
              }}
              initialValues={{
                name:getUserDetails?.name,
                phone: getUserDetails?.phone,
                email: getUserDetails?.email,
                address:getUserDetails?.address,
                city:getUserDetails?.city,
                state:getUserDetails?.state,
                zipcode:getUserDetails?.zipcode,
                firmname:getUserDetails?.firmname,
                secondPhone:getUserDetails?.secondPhone,
                gstNo:getUserDetails?.gstNo,
                gst:getUserDetails?.gst,
                aadharNo:getUserDetails?.aadharNo,
                aadhar:getUserDetails?.aadhar,
                password: getUserDetails?.realPassword,
                id:userId,
                status:getUserDetails?.status
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                setFieldValue,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6} sx={12} sm={12} className="mb-1">
                      <Form.Group>
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-person-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                            className="form-control"
                            placeholder="Enter Proprietor/Partner Name"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6} sx={12} sm={12} className="mb-1">
                      <Form.Group>
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-envelope-fill"></i>
                          </div>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                            className="form-control"
                            placeholder="Email"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12} sm={12} className="mb-1">
                    <Form.Group>
                          <div className="input-group">
                            <div className="input-group-text">
                              <i className="bi bi-phone-fill"></i>
                            </div>
                            <Form.Control
                              type="text"
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                              isInvalid={!!errors.phone}
                              className="form-control"
                              placeholder="Phone"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                    </Col>
                    <Col md={6} xs={12} sm={12} className="mb-1">
                        <Form.Group>
                          <div className="input-group">
                            <div className="input-group-text">
                            <i className="bi bi-geo-alt-fill"></i>
                            </div>
                            <Form.Control
                              type="text"
                              name="address"
                              value={values.address}
                              onChange={handleChange}
                              isInvalid={!!errors.address}
                              className="form-control"
                              placeholder="Enter Address"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                            {errors.address}
                          </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} xs={12} sm={12} className="mb-1">
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-geo-alt-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            isInvalid={!!errors.city}
                            className="form-control"
                            placeholder="City"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={4} xs={12} sm={12} className="mb-1">
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-geo-alt-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="zipcode"
                            value={values.zipcode}
                            onChange={handleChange}
                            isInvalid={!!errors.zipcode}
                            className="form-control"
                            placeholder="PIN Code"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.zipcode}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={4} xs={12} sm={12} className="mb-1">
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-geo-alt-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                            isInvalid={!!errors.state}
                            className="form-control"
                            placeholder="State"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.state}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col className="text-center">
                    <p className="mb-0"><b>Firm Details(All Details According To GST)</b></p>
                    <img className={width.current > 480 ? "width-auto" : "w-100"} src={imgborder}/>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={6} xs={12} sm={12} className="mb-1">
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-building-fill-add"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="firmname"
                            value={values.firmname}
                            onChange={handleChange}
                            isInvalid={!!errors.firmname}
                            className="form-control"
                            placeholder="FirmName"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.firmname}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6} xs={12} sm={12} className="mb-1">
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-phone-fill"></i>
                          </div>
                          <Form.Control
                            type="number"
                            name="secondPhone"
                            value={values.secondPhone}
                            onChange={handleChange}
                            isInvalid={!!errors.secondPhone}
                            className="form-control"
                            placeholder="Alternative Phone No."
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.secondPhone}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={6} xs={12} sm={12} className="mb-1">
                    <Form.Group as={Col} md="12">
                    <Form.Label>Enter GST Number</Form.Label>
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-file-earmark-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="gstNo"
                            value={values.gstNo}
                            onChange={handleChange}
                            isInvalid={!!errors.gstNo}
                            className="form-control"
                            placeholder="GST Number"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.gstNo}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6} xs={12} sm={12} className="mb-1">
                      <Form.Group as={Col} md="12">
                        <Form.Label>GST Image</Form.Label>
                        <Form.Control
                          id="myfile"
                          type="file"
                          name="gst"
                          onChange={(event) => {
                            const varientImg = event.target.files[0];
                            setFieldValue("gst", varientImg);
                          }}
                          isInvalid={!!errors.gst}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.gst}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={6} xs={12} sm={12} className="mb-1">
                      <Form.Group as={Col} md="12">
                      <Form.Label>Enter Aadhar No.</Form.Label>
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-file-earmark-fill"></i>
                          </div>
                          <Form.Control
                            type="number"
                            name="aadharNo"
                            value={values.aadharNo}
                            onChange={handleChange}
                            isInvalid={!!errors.aadharNo}
                            className="form-control"
                            placeholder="Aadhar No."
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                          {errors.aadharNo}
                        </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6} xs={12} sm={12} className="mb-1">
                      <Form.Group as={Col} md="12">
                          <Form.Label>Aadhar Image</Form.Label>
                          <Form.Control
                            id="myfile"
                            type="file"
                            name="aadhar"
                            onChange={(event) => {
                              const varientImg = event.target.files[0];
                              setFieldValue("aadhar", varientImg);
                            }}
                            isInvalid={!!errors.aadhar}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.aadhar}
                          </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12} sm={12} className="mb-2">
                    <Form.Label>Password</Form.Label>
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                          className="form-control"
                          placeholder="Password"
                          required
                        />
                         <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    </Col>
                    {((localStorage.getItem("userRole") !== "dealer") && userId) && <Col  md={6} xs={6} sm={6} className="mb-2">
                    <Form.Label>Dealer Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("status", (/true/).test(e.target.value));
                      }}
                      name="status"
                      className="form-control"
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </Form.Control>
                  </Col>}
                  </Row>
                 
                  <div className="col-12 text-center">
                    <Button className="btn-color w-100" type="submit">
                     {(userId || localStorage.getItem("userRole") === "dealer") ? "Update" : "Sign Up"} 
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="col-12 text-center">
            <p>
                <Link to={"/"}>
                  <b>
                    <i className="bi bi-arrow-left"></i> Back to Home
                  </b>
                </Link>
              </p>
            </div>
          </Col>
          {
            width.current > 480 && <Col md={5}>
            <img className="w-100" src={signupimg}/>
            </Col>
          }
        </Row>
      </div>
    </>
  );
};

export default SignUp;
