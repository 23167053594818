import React, {useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Update from "./Update";
import Form from "react-bootstrap/Form";
import {
  getUsers, getUserRole, resetUsers, categoryList, userDelete, getStates
} from "../../reducers/commonReducer";

const List = () => { 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState( );
  const [plant, setPlant] = useState();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getUserRole())
    dispatch(categoryList())
    dispatch(getStates())
  }, []);
  useEffect(() => {
    let timeOut = setTimeout(() => {
    dispatch(resetUsers())
    dispatch(getUsers({
      search
    }));
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { getUsersData, getUserRoleList, categoryListData, getUsersDataBlank, userCount } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <div className="container">
        <div className="mt-4 row">
          <div className="col-md-6 col-6">
            <h3>Users List ({userCount})</h3>
          </div>
          {
            ((localStorage.getItem("userRole") === "dealer") || (localStorage.getItem("userRole") === "admin") || (localStorage.getItem("userRole") === "superadmin")) && <div className="col-md-6 text-end col-6">
            <Button
              className="mb-3 mobile-width submit-buttom"
              onClick={() => navigate("/add-user")}
            >
              Add User
            </Button>
          </div>
          }
          
          <div className="col-md-12 p-0">
                  <Row className=" mb-3 border pt-3 pb-3 justify-content-between">
                    <Col md={4} sm={12}>
                      <Form.Label>Name, City, State</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search..."
                        name="name"
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                      />
                    </Col>
                    {
                      ((localStorage.getItem("userRole") === "admin") || (localStorage.getItem("userRole") === "superadmin")) && <>
                        <Col md={2} sm={6} xs={6}>
                      <Form.Label>Role</Form.Label>
                      <Form.Control
                        as="select"
                        name="role"
                        value={role}
                        onChange={(e) => {
                          dispatch(resetUsers())
                          dispatch(getUsers({
                            role:e.target.value,
                          }))
                          setRole(e.target.value)
                        }}
                        className="form-control"
                      >
                        <option value="">All</option>
                        {
                          !!getUserRoleList && getUserRoleList?.map((data, index) => <option key={index} value={data?.slug}>{data?.name}</option>)
                        }
                      </Form.Control>
                    </Col>
                    <Col md={2} sm={6} xs={6}>
                      <Form.Label>Plant</Form.Label>
                      <Form.Control
                        as="select"
                        name="plant"
                        value={plant}
                        onChange={(e) => {
                          dispatch(resetUsers())
                          dispatch(getUsers({
                            plant: e.target.value?.length > 0 ? [e.target.value] : "",
                          }))
                          setPlant(e.target.value)
                        }}
                        className="form-control"
                      >
                        <option value="">All</option>
                        {
                          !!categoryListData && categoryListData?.map((data, index) => <option key={index} value={data?.id}>{data?.name}</option>)
                        }
                      </Form.Control>
                    </Col>
                      </>
                    }
                    <Col md={2} sm={6} xs={6}>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={status}
                        onChange={(e) => {
                          dispatch(resetUsers())
                          dispatch(getUsers({
                            status:e.target.value,
                          }))
                          setStatus(e.target.value)
                        }}
                        className="form-control"
                      >
                        <option value="">All</option>
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                      </Form.Control>
                    </Col>
                    <Col md={2} sm={6} xs={6}>
                      <Button className="mt-4 float-right" variant="danger" onClick={() => window.location.reload()}>Refresh</Button>
                      </Col>
                  </Row>
                
          </div>
          <div className="overflow-auto border mt-3">
            <Table striped hover size="sm" className="overflow-scroll">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Plant</th>
                  <th>Assign State</th>
                  <th>Phone/Email</th>
                  <th>Role</th>
                  <th>Address</th>
                  <th>Status</th>
                  {
                   ((localStorage.getItem("userRole") === "admin") || (localStorage.getItem("userRole") === "superadmin")) && <th>Action</th>
                  }
                </tr>
              </thead>
              <tbody>
                {!!getUsersData &&
                  getUsersData.map((data, index) => {
                    return (
                      <tr key={data?.id}>
                        <td data-label="Sr">{index + 1}.</td>
                        <td data-label="Name">
                          {data?.name} ({data?.username} {((localStorage.getItem("userRole") === "admin") || (localStorage.getItem("userRole") === "superadmin")) && <b>/{data?.realpassword}</b>})<br/>
                          {
                            data?.role?.slug === "dealer" && <>
                            <Link to={`/orders?userid=${data?.id}`}><Button variant="success" className="m-1 p-1">View Order</Button></Link>
                            {
                              ((localStorage.getItem("userRole") !== "manager") && (localStorage.getItem("userRole") !== "marketing")) && <>
                              <Link to={`/payment?userid=${data?.id}`}><Button variant="warning" className="m-1 p-1">Payments</Button></Link>
                              <Link to={`/product-assign?userid=${data?.id}`}><Button variant="info" className="m-1 p-1">Products</Button></Link>
                              </>
                            }
                            </>
                          }
                          {/* {
                            data?.role?.slug === "dealer" && <>
                            <Link to={`/orders?userid=${data?.id}`}><Button variant="success" className="m-1 p-1">View Order</Button></Link>
                            {((localStorage.getItem("userRole") !== "manager") || (localStorage.getItem("userRole") !== "marketing")) && <Link to={`/payment?userid=${data?.id}`}><Button variant="warning" className="m-1 p-1">Payments</Button></Link>}<br/><Link to={`/product-assign?userid=${data?.id}`}><Button variant="info" className="m-1 p-1">Products</Button></Link>
                            </>
                          } */}
                        </td>
                        <td data-label="Plant">{data?.plant?.map((plant, pindx) => <span key={pindx}>{plant?.name}, </span>)}</td>
                        <td data-label="Assign State">{data?.assignState?.map((state, pindx) => <span key={pindx}>{state}, </span>)}</td>
                        <td data-label="Phone/Email">{data?.phone}<br/>{data?.email}</td>
                        <td data-label="Role"><span className={
                          data?.role?.slug === "manager" && "text-success" || 
                          data?.role?.slug === "dealer" && "text-warning" || 
                          data?.role?.slug === "marketing" && "text-info"}><b>{data?.role?.name}</b></span></td>
                        <td data-label="Address">{`${data?.address}, ${data?.city}, ${data?.state}`}</td>
                        <td data-label="Status">
                          {data?.status === true ? (
                            <b className="text-success">Active</b>
                          ) : (
                            <b className="text-danger">Inactive</b>
                          )}
                        </td>
                        {
                          ((localStorage.getItem("userRole") === "admin") || (localStorage.getItem("userRole") === "superadmin")) && <td data-label="Action">
                            
                            <Update
                            fName={data?.name}
                            userAdrs={data?.address}
                            userCity={data?.city}
                            userState={data?.state}
                            userPhone={data?.phone}
                            userEmail={data?.email}
                            status={data?.status}
                            assignState = {data?.assignState}
                            id={data?.id}
                            role={data?.role?.slug}
                          />
                          <Button variant="danger"
                          onClick={() => {
                            dispatch(userDelete({
                              id:data?.id
                            }))
                            setTimeout(() => {
                              dispatch(resetUsers())
                              dispatch(getUsers());
                              }, 800);
                          }}
                          ><i className="bi bi-trash3-fill"></i></Button>
                        </td>
                        }
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
        <Row className="mt-2">
          <Col className="p-0">
            {!!getUsersDataBlank && getUsersDataBlank.length > 0 ? (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getUsers({
                      page: page + 1,
                      role,status,plant
                    })
                  );
                }}
              >
                Load More
              </Button>
            ) : (
              <p className="text-center">
                <b>No record found 😔.</b>
              </p>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default List;
