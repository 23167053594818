import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  addCategory,
  categoryList,
  getSettings,
  updateSetting,
} from "../../reducers/commonReducer";
import { Formik } from "formik";
import * as yup from "yup";

// Validation by Yup
const schema = yup.object().shape({
  name: yup.string().required(),
});

const Categorys = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(categoryList()
    );
  }, []);
  const { categoryListData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <div className="container">
        <div className="mt-4 row">
          <div className="col-md-6 col-6 p-0">
            <h3>Add Plant</h3>
          </div>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              await dispatch(addCategory(values));
              await dispatch(categoryList());
              resetForm({ values: "" });
            }}
            initialValues={{
              name: "",
              description:''
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit} className="p-0">
                <Row className="mb-4">
                  <Form.Group as={Col} md="12">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-person-fill"></i>
                      </div>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                        className="form-control"
                        placeholder="Plant Name"
                        required
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="col-12 text-center mt-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-pen-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          as="textarea"
                          row={3}
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          isInvalid={!!errors.description}
                          className="form-control"
                          placeholder="Write Description....."
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-center">
                    <Button
                      type="submit"
                      className=" button-color login-btn px-4 mt-2 w-100"
                    >
                      Add New Plant
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
          <div className="col-md-6 col-6 p-0">
            <h3>Plant List</h3>
          </div>
          <div className="overflow-auto border mt-3">
            <Table striped hover size="sm" className="overflow-scroll">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {!!categoryListData &&
                  categoryListData?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="Sr">{index + 1}.</td>
                        <td data-label="Name">{data?.name}</td>
                        <td data-label="Description">{data?.description}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export const TableRow = (props) => {
  const { value, itemId } = props;
  const dispatch = useDispatch();
  const [updateData, setUpdateData] = useState(value);
  return (
    <Form.Group className="mb-3">
      <Form.Control
        type="text"
        value={updateData}
        onBlur={() => {
          dispatch(updateSetting({ id: itemId, value: updateData }));
        }}
        onChange={(e) => setUpdateData(e.target.value)}
      />
    </Form.Group>
  );
};
export default Categorys;
