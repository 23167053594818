import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';

function ProductDetail({title, variants, heading, status}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { getAttributesList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Button variant="warning" onClick={handleShow}>
        {title}
      </Button>
      <Modal size={"lg"} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <hr className='m-0'/>
        <Modal.Body>
          <Table striped>
            <thead>
              <tr>
                <th>SrNo.</th>
                {/* <th>Price</th> */}
                <th>Status</th>
                <th>Attributes</th>
              </tr>
            </thead>
            <tbody>
              {
                !!variants && variants?.map((data, index) => {
                  return(
                    <tr key={index}>
                      <td data-label="Sr">{index + 1}.</td>
                      {/* <td data-label="Price">{data?.price}/-</td> */}
                      <td data-label="Qty">{data?.status ? <span className='text-success'>Active</span> : <span className='text-danger'>Inactive</span>}</td>
                      <td data-label="Attributes">
                        {!!data?.attributes && Object.entries(data?.attributes).map(([key, value]) => (
                          <p className="mb-0" key={key}><strong>{!!getAttributesList && getAttributesList?.[key]}:</strong> {value}</p>
                        ))}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProductDetail;