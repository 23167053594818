import React, { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import Header from "../../Components/Header";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {  addUser, categoryList, getStates, getUserRole } from "../../reducers/commonReducer";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email("Invalid email address").required(),
  address: yup.string().required("Please Enter Address."),
  city: yup.string().required("Please Enter city."),
  state: yup.string().required("Please Enter state."),
  phone: yup.string().min(10).max(10).required("Please Enter Phone number."),
  status: yup.string().required(""),
  role:yup.string().required("Please Assign User Role"),
  password: yup.string().required("Password is required."),
  plant: yup.array().required("Plant is required."),
  assignState: yup.array().when('role', {
    is: (role) => role === 'marketing',
    then: yup.array().required('Assign State is required for marketing person'),
    otherwise: yup.array() 
  }),
  balance: yup
  .mixed()
  .when('role', {
    is: 'dealer',
    then: yup.number().required('Balance is required for dealers'),
    otherwise: yup.number()
  })
});

function Add() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getUserRole())
    dispatch(categoryList())
    dispatch(getStates())
  },[])
  const api = async (values) => {
    const apiResponse = await dispatch(addUser(values));
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        navigate("/users");
      }, 1000);
    }
    return null;
  };
  const { getUserRoleList, getStatesList, categoryListData } = useSelector((state) => state.commonReducer);
  return (
    <>
    <Header/>
    <div className="container mt-4">
    <Formik
        validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
             dispatch(api(values));
            resetForm({ values: "" });
          }}
          initialValues={{
            name: '',
            address: '',
            city: '',
            state: '',
            phone: '',
            email: '',
            status: true,
            password:'',
            role:'',
            plant:'',
            balance:'',
            assignState:''
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="city"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="state"
                    className="form-control"
                    value={values.state}
                    isInvalid={!!errors.state}
                  >
                    <option value=''>Select State</option>
                    {
                      !!getStatesList && getStatesList?.map((state, index) => <option key={index} value={state} >{state}</option>)
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("role", e.target.value);
                    }}
                    name="role"
                    className="form-control"
                    value={values.role}
                    isInvalid={!!errors.role}
                  >
                    <option value={''} >Select Role</option>
                    {
                      !!getUserRoleList && getUserRoleList?.map((data, index) => <option key={index} value={data?.slug} >{data?.name}</option>)
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.role}
                    </Form.Control.Feedback>
                </Col>
                {
                 values.role === "dealer" && <Col md={3}>
                  <Form.Group>
                    <Form.Label>Balance</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Balance"
                      name="balance"
                      value={values.balance}
                      onChange={handleChange}
                      isInvalid={!!errors.balance}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.balance}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                }
                {
                  values.role === "marketing" && <Col md={3}>
                  <Form.Label>Assign State</Form.Label>
                  <Form.Control
                    as="select"
                    multiple={true}
                    onChange={handleChange}
                    name="assignState"
                    className="form-control"
                    value={values.assignState}
                    isInvalid={!!errors.assignState}
                  >
                    {
                      !!getStatesList && getStatesList?.map((state, index) => <option key={index} value={state} >{state}</option>)
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                      {errors.assignState}
                    </Form.Control.Feedback>
                </Col>
                }
                <Col md={3} className="mb-2">
                  <Form.Label>Plant</Form.Label>
                  <Form.Control
                    multiple={true}
                    as="select"
                    onChange={handleChange}
                    name="plant"
                    className="form-control"
                    value={values.plant}
                    isInvalid={!!errors.plant}
                  > 
                    {
                      !!categoryListData && categoryListData?.map((plant, index) => <option key={index} value={plant?.id}>{plant?.name}</option>)
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.plant}
                  </Form.Control.Feedback>
                </Col>
                 <Col md={3}>
                  <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" className="w-100" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
        </div>
    </>
  );
}

export default Add;
