import { toast } from "react-toastify";

export const messages = async (msg, apiStatus) => {
  if (apiStatus === 1) {
    toast.success(msg );
  } else {
    toast.error(msg );
  }
};

export const postApi = async (api, type, body) => {
  const res = await fetch(api, {
    method: type,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("x-auth-token"),
    },
    body: JSON.stringify(body),
  });
  return await res.json();
};

export const allApi = async (api, type) => {
  const res = await fetch(api, {
    method: type,
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("x-auth-token"),
    },
  });
  return await res.json();
};

export const productFileWithArray = async (api, type, details) => {
  const formData = new FormData();
  formData.append('extradata', JSON.stringify(details));
  Object.entries(details).forEach(([key, value]) => {
    if(key == 'image') {
      formData.append(key, value);
    }  
  });
  const myHeaders = new Headers();
  myHeaders.append("x-auth-token", localStorage.getItem("x-auth-token"));
  const res = await fetch(api, {
    method: type,
    body: formData,
    headers: myHeaders,
  });
  return await res.json();
};

export const postApiFile = async (api, type, details) => {
  const formData = new FormData();
  Object.entries(details).map(([key, value]) => {
    formData.append(key, value);
  });
  const myHeaders = new Headers();
  myHeaders.append("x-auth-token", localStorage.getItem("x-auth-token"));
  const res = await fetch(api, {
    method: type,
    body: formData,
    headers: myHeaders,
  });
  return await res.json();
};

export const fileWithObjArray = async (api, type, userDetails) => {
  const formData = new FormData();

  const fields = [
    "firstName",
    "firmName",
    "lastName",
    "address",
    "city",
    "email",
    "gst",
    "gstimage",
    "image",
    "password",
    "state",
  ];

  fields.forEach((field) => {
    if (userDetails?.[field]) {
      formData.append(field, userDetails[field]);
    }
  });

  // if (userDetails?.products) {
  //   formData.append("products", JSON.stringify(userDetails.products));
  // }
  if (userDetails?.EDIBLE) {
    formData.append("EDIBLE", JSON.stringify(userDetails.EDIBLE));
  }
  if (userDetails?.NONEDIBLE) {
    formData.append("NONEDIBLE", JSON.stringify(userDetails.NONEDIBLE));
  }
  if (userDetails?.CHEMICAL) {
    formData.append("CHEMICAL", JSON.stringify(userDetails.CHEMICAL));
  }
  if (userDetails?.brokers) {
    formData.append("brokers", JSON.stringify(userDetails.brokers));
  }

  const myHeaders = new Headers();
  myHeaders.append("x-auth-token", localStorage.getItem("x-auth-token"));

  const res = await fetch(api, {
    method: type,
    body: formData,
    headers: myHeaders,
  });

  return await res.json();
};


