import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { addVarient, getVarient, updateVarient } from "../../reducers/commonReducer";
import red from "../../images/red.png";
import green from "../../images/green.png";
import VarientAction from "./VarientAction";
import { Button } from "react-bootstrap";

const VarientList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getVarient());
  }, []);
  const { getVarientList } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Varient</h3>
          </Col>  
          <hr/>
          <Col md={6}>
            <VarientAction
             title="Add Varient"
             btnTitle="Add"
             api={addVarient}
             />
          </Col>
        </Row>
          
          <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Name</th>
              <th>Description</th>
              <th>Img</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getVarientList &&
              getVarientList.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>
                      {data?.name}
                    </td>
                    <td>{data?.description}</td>
                    <td><img className="max-width-30" src={`/${data?.image}`}/></td>
                   <td className="table-img">
                      {data?.status === true ? <img src={green} /> : <img src={red} />}
                    </td>
                    <td>
                      <VarientAction
                        title="Update Varient"
                        btnTitle="Edit"
                        name={data?.name}
                        description={data?.description}
                        status={data?.status}
                        id={data?.id}
                        api={updateVarient}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table> 
        </div>
      </div>
    </>
  );
};

export default VarientList;
