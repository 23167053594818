import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { getOrder, replacementList } from "../../reducers/commonReducer";
import { InputGroup, Form, Button } from "react-bootstrap";
import ReplacementWarrantyAction from "./ReplacementWarrantyAction";
import moment from "moment";

const ReplacementWarranty = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(replacementList())
  }, []);
  const clear = () => {
    setSearch('')
    dispatch(replacementList())
  }
  const { getOrderDetails, replacementDataList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Replacement & Warranty</h3>
          </Col>  
          <hr/>
        </Row>
        <Row className="mb-3">
        <Col className="text-end" md={6}>
          <div className="d-flex">
          <InputGroup>
            <Form.Control value={search} onChange={(e) => {
                setSearch(e.target.value)
              }} />
            <InputGroup.Text className="pointer bg-success" onClick={() => {
              dispatch(getOrder({
                search
              }))
            }}><i className="bi bi-search text-white"></i></InputGroup.Text>
          </InputGroup>
          <Button variant="danger" onClick={() => clear()}>Clear</Button>
          </div>
          </Col>
          {
            (localStorage.getItem("userRole") !== "dealer") && <Col md={6} sm={12} xs={12} className={width.current < 500 ? 'mb-1 mt-1' : ''}> 
            <ReplacementWarrantyAction
             title="Add Replacement"
             btnTitle="Add Replacement"
             search={search}
             />
          </Col>
          }
        </Row>
        <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>OrderID</th>
              <th>Serial Numbers</th>
              <th>Date</th>
              <th>Status</th>
              {
                (localStorage.getItem("userRole") !== "dealer") &&  <th>Action</th>
              }
            </tr>
          </thead>
          <tbody>
            {!!getOrderDetails &&
              getOrderDetails?.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td data-label="Sr">{index + 1}.</td>
                    <td data-label="OrderID">{data?.orderid}</td>
                    <td data-label="Serial Numbers">{data?.serialnumbers?.map((item) => <span>{item}, </span>)}</td>
                    <td data-label="Date">{moment(data?.createdAt).format("LLL")}</td>
                    <td data-label="Status"><b>{data?.status}</b></td>
                    {
                      (localStorage.getItem("userRole") !== "dealer") && <td data-label="Action">
                      <ReplacementWarrantyAction
                        title="Create Replacement"
                        btnTitle="Create Replacement"
                        name={data?.name}
                        description={data?.description}
                        status={data?.status}
                        id={data?.id}
                        search={search}
                        orderid={data?.id}
                      />
                    </td>
                    }
                  </tr>
                );
              })}
          </tbody>
        </Table> 
        </div>
        <div className="overflow-auto mt-5">
          <h3>Replace Items</h3>
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Order ID</th>
              <th>Product</th>
              <th>IDD</th>
              <th>SR Number</th>
              <th>Replaced With</th>
              <th>Comment</th>
              <th>Date</th>
              <th>Status</th>
              {
                (localStorage.getItem("userRole") !== "dealer") && <th>Action</th>
              }
            </tr>
          </thead>
          <tbody>
            {!!replacementDataList &&
              replacementDataList?.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td data-label="Sr">{index + 1}.</td>
                    <td data-label="Order ID">{data?.orderid?.orderid}</td>
                    <td data-label="Product"><b>{data?.productName}</b><br/>{data?.otherProduct}</td>
                    <td data-label="IDD">{data?.idd}</td>
                    <td data-label="SR Number">{data?.srNumber}</td>
                    <td data-label="Replaced With">{data?.replacedWith}</td>
                    <td data-label="Comment">{data?.comment}</td>
                    <td data-label="Date"> {moment(data?.createdAt).format("LLL")}</td>
                    <td data-label="Status"><b>{data?.status}</b></td>
                    {
                      (localStorage.getItem("userRole") !== "dealer") && <td data-label="Action">
                      <ReplacementWarrantyAction
                        title="Update Replacement"
                        btnTitle="Update Replacement"
                        otherProduct={data?.otherProduct}
                        productName={data?.productName}
                        description={data?.description}
                        status={data?.status}
                        srNumber={data?.srNumber}
                        id={data?.id}
                      />
                    </td>
                    }
                  </tr>
                );
              })}
          </tbody>
        </Table> 
        </div>
      </div>
    </>
  );
};

export default ReplacementWarranty;
