import { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { getOrders, resetOrders, updateOrder } from '../../reducers/commonReducer';

const schema = yup.object().shape({
    status: yup.string().required(),
  });

function OrderUpdate({title, status, serialnumbers, id, address, state, city, pincode, vehicleNo, transportName, driverNo}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        {title}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Order</Modal.Title>
        </Modal.Header>
        <hr className='m-0'/>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(updateOrder(values));
            resetForm({ values: "" });
            dispatch(resetOrders())
            dispatch(getOrders())
            setShow(false)
          }}
          initialValues={{
            status,
            id,
            serialnumbers,
            address,
            state,
            city,
            pincode,
            vehicleNo, transportName, driverNo
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={handleChange}
                        name="status"
                        className="form-control"
                        value={values.status}
                        isInvalid={!!errors.status}
                    > 
                        <option value="">Select Status</option>
                        <option value={"Pending"}>Pending</option>
                        <option value={"Processing"}>Processing</option>
                        <option value={"Completed"}>Completed</option>
                        <option value={"Cancel"}>Cancel</option>
                    </Form.Control>
                    </Col>
                </Row>
                <Row className='mt-2'>
                  <Col md={12}>
                    <Form.Group >
                      <Form.Label>Serial Numbers</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="C34224, B464225, P23456"
                        name="serialnumbers"
                        value={values.serialnumbers}
                        onChange={handleChange}
                        isInvalid={!!errors.serialnumbers}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.serialnumbers}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col md={6}>
                    <Form.Group >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        isInvalid={!!errors.address}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group >
                      <Form.Label>PinCode</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="PIN Code"
                        name="pincode"
                        value={values.pincode}
                        onChange={handleChange}
                        isInvalid={!!errors.pincode}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.pincode}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-2'>
                <Col md={6}>
                    <Form.Group >
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter city"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        isInvalid={!!errors.city}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group >
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter State"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        isInvalid={!!errors.state}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.state}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col md={4}>
                    <Form.Group >
                      <Form.Label>Vehicle No.</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="vehicleNo"
                        name="vehicleNo"
                        value={values.vehicleNo}
                        onChange={handleChange}
                        isInvalid={!!errors.vehicleNo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.vehicleNo}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group >
                      <Form.Label>Transport Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="transportName"
                        name="transportName"
                        value={values.transportName}
                        onChange={handleChange}
                        isInvalid={!!errors.transportName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.transportName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group >
                      <Form.Label>Driver No.</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="driverNo"
                        name="driverNo"
                        value={values.driverNo}
                        onChange={handleChange}
                        isInvalid={!!errors.driverNo}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.driverNo}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button variant="danger" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Form>
            )}
        </Formik>
      </Modal>
    </>
  );
}

export default OrderUpdate;