import React, { Fragment, useEffect, useRef, useState } from "react"; 
import { Link } from "react-router-dom";
import { Form, Row, Col, InputGroup, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addCart, addOrder, getAttributes, getCartList, getOrder, getUser, singleProduct, updateOrder } from "../../reducers/commonReducer";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import banner from '../../images/signup.jpg'

const schema = yup.object().shape({
  product: yup.string().required("Please Select Product."),
  quantity : yup.number().required("Please Enter Product Quantity."),
});
function OrderAction(){
  const [inputValues, setInputValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderId");
  const productid = queryParameters.get("productid");
  const width = useRef(window.innerWidth);

  useEffect(() => {
    dispatch(singleProduct({
      id:productid
    }))
    dispatch(getAttributes())
  },[])
  const { singleProductData, varientsList, getAttributesList } = useSelector((state) => state.commonReducer);
  var notFoundVariable = 0;
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };
  const scrollToDesc = (id) => {
    const descSection = document.getElementById(id);
    if (descSection) {
      descSection.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  const maxWords = 50;
  const descriptionWords = singleProductData?.description.split(' ');
  const truncatedDescription = descriptionWords?.slice(0, maxWords).join(' ');
  const showReadMore = descriptionWords?.length > maxWords;
  return (
    <>
    <Header/>
        <Container>
          <Row className="mt-5">
                <Col md={6}>
                  {
                    !!singleProductData?.image && <img className="w-100" src={`/${singleProductData?.image}`}/>
                  }
                </Col>
                <Col md={6} className="products-discription">
                <h4><b> {singleProductData?.name}</b></h4>  
                 <Row className="align-center">
                      <Col md={6}>
                      <span className="bi bi-star-fill rated "></span>
                      <span className="bi bi-star-fill rated "></span>
                      <span className="bi bi-star-fill rated "></span>
                      <span className="bi bi-star-fill rated "></span>
                      <span className="bi bi-star-fill rated"></span> 
                      </Col>
                 </Row>
                 <Row>
                 <Col md={6} className="variant-btn" onClick={() => scrollToDesc("variant")}>
                    View Variants <i className="bi bi-arrow-down-circle"></i>
                  </Col>
                 </Row>
                  <hr/>
                  <div dangerouslySetInnerHTML={{ __html: truncatedDescription + (descriptionWords?.length > maxWords ? '...' : '') }} />
                  {showReadMore && <Button onClick={() => scrollToDesc("desc")} variant="success">Read More <i className="bi bi-arrow-right-circle"></i></Button>}
                   <div className="row align-center mt-4"> 
                       <Col md={3} sm={6} xs={6} className="mb-2">
                        <div className="features">
                        <i className="bi bi-truck-front-fill"></i>
                        <p className="mb-0">Fast Shipping</p>
                        </div>
                       </Col> 
                       <Col md={3} sm={6} xs={6} className="mb-2">
                        <div className="features">
                        <i className="bi bi-cash-coin"></i>
                        <p className="mb-0">Lowest Rates</p>
                        </div>
                       </Col>
                       <Col md={3} sm={6} xs={6} className="mb-2">
                        <div className="features">
                        <i className="bi bi-telephone-inbound-fill"></i>
                        <p className="mb-0">24/7 Support </p>
                        </div>
                       </Col>
                       <Col md={3} sm={6} xs={6} className="mb-2">
                        <div className="features">
                        <i className="bi bi-credit-card-2-front-fill"></i>
                        <p className="mb-0">Secure Payment</p>
                        </div>
                       </Col>
                   </div>
                </Col>
              </Row>
              <Row id="variant">
                <Col md={12}>
                  <h4>{singleProductData?.name} Variants</h4>
                </Col>
                <hr className="mb-0"/>
              </Row> 
              <Row className="mt-3">
              {!!singleProductData?.variants && singleProductData?.variants?.map((item, itemindex) => {
                    varientsList?.[item?.id] ? notFoundVariable = 1 : notFoundVariable = 0
                    return(
                      <Fragment key={itemindex}>
                        {
                          (varientsList?.[item?.id] > 0) && <>
                          {!!item?.attributes && Object.entries(item?.attributes)?.map(([key, value]) => (
                          <Col key={key}>
                            <strong>{!!getAttributesList && getAttributesList?.[key]}:</strong> {value}
                          </Col>
                        ))}
                        <Col className={width.current < 500 ? "" : 'p-0'} md={1} sm={12} xs={12}>
                          <p className="mb-0"><b>Rs: </b><span className={varientsList?.[item?.id] && "text-success"}>{varientsList?.[item?.id]}/-</span></p>
                        </Col>
                        <Col md={2} sm={8} xs={8} className="mb-1">
                          <Form.Control
                            as="select"
                            placeholder="Color"
                            id={`color${itemindex}`}
                            value={inputValues[`color${itemindex}`] || ''}
                            onChange={(e) => handleInputChange(e, itemindex)}
                            className="form-control"
                          > 
                            <option value="">Select Color</option>
                            <option value={'Black'}>Black</option>
                            <option value={'White'}>White</option>
                            <option value={'Silver/Gray'}>Silver/Gray</option>
                            <option value={'Blue'}>Blue</option>
                            <option value={'Red'}>Red</option>
                            <option value={'Green'}>Green</option>
                            <option value={'Yellow'}>Yellow</option>
                            <option value={'Orange'}>Orange</option>
                            <option value={'Pink'}>Pink</option>
                            <option value={'Purple'}>Purple</option>
                          </Form.Control>
                        </Col>
                        <Col md={1} sm={8} xs={8} className="mb-1">
                          <Form.Group >
                            <InputGroup>
                            <Form.Control
                              type="number"
                              placeholder="Qty."
                              id={`quantity${itemindex}`}
                              value={inputValues[`quantity${itemindex}`] || ''}
                              onChange={(e) => handleInputChange(e, itemindex)}
                            />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col md={1} sm={4} xs={4} className="text-end">
                          <Button variant="success"
                          onClick={async () => {
                            const inputValue = inputValues[`quantity${itemindex}`];
                            const selectColor = inputValues[`color${itemindex}`];
                            if (inputValue > 0) {
                              const apiResp = await dispatch(addCart({
                                varient:item?.id,
                                quantity: +inputValue,
                                color: selectColor
                              }));
                              if (apiResp.payload?.status === 1) {
                                dispatch(getCartList())
                                setInputValues({
                                  ...inputValues,
                                  [`quantity${itemindex}`]: "",
                                  [`color${itemindex}`]: ""
                                });
                              }
                            }
                          }}
                          >
                            <i className="bi bi-cart-check-fill"></i>
                          </Button>
                        </Col>
                          <hr/>
                          </>
                        }
                      </Fragment>
                    )
                  })}
                  {/* {
                    notFoundVariable === 0 && <h2 className="text-danger text-center">Not Available {singleProductData?.name} Variants</h2>
                  } */}
              </Row> 
              <Row className="mt-4">
                <Col md={12} id="desc">
                  <h4><b>Product Details</b></h4>
                </Col>
                <hr/>
              </Row>
              <Row>
                <Col md={9} className="word-wrap" dangerouslySetInnerHTML={{ __html: singleProductData?.description}} />
                <Col md={3}>
                  <Link to={"/products-detail"}>
                  <img className="w-100" src={banner}/>
                  </Link>
                </Col>
              </Row>
        </Container>
    </>
  );
}

export default OrderAction;
