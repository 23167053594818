import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Header from "../../Components/Header";
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getProducts } from '../../reducers/commonReducer';
import { Link } from 'react-router-dom';

function ProductCard() {
    const dispatch = useDispatch();
    useEffect(() =>{
        dispatch(getProducts())
    },[])
const { getProductsData } = useSelector((state) => state.commonReducer);
  return (
    <>
        <Header />
        <Container className='mt-5'>
        <Row>
  { !!getProductsData && getProductsData?.map((data, index) => {
      return (
        <Col md={4} className="mb-2" key={index}>
          <Card className='shadow h-100 border border-color'>
            <Card.Img variant="top" src={data?.image} className="img-fluid" alt={data?.name} />
            <Card.Body className='p-0'>
              <div className='p-2 text-center'>
              <Card.Title>
                <hr className='mt-3 mb-3'/>
                <p className="text-truncate-2 mb-0"> {data?.name} </p> 
                <hr className='mt-3 mb-3'/>
                    <span className="bi bi-star-fill rated "></span>
                    <span className="bi bi-star-fill rated "></span>
                    <span className="bi bi-star-fill rated "></span>
                    <span className="bi bi-star-fill rated "></span>
                    <span className="bi bi-star-fill rated"></span> 
              </Card.Title>
              {/* <Card.Text>
                <p className='mb-0'><b>Rs. {data?.price}/-</b></p>
              </Card.Text> */}
              </div>
              <Link to={`/orders-action?productid=${data?.id}`}>
                <Button className='w-50 bg-main-theme radius-0 border-0 pt-3 pb-3'>Read More <i className="bi bi-arrow-right-circle"></i></Button>
              </Link>
              <Link to={`/orders-action?productid=${data?.id}`}>
                <Button className='w-50 radius-0 border-0 pt-3 pb-3'>Variant <i className="bi bi-arrow-right-circle"></i></Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      );
    })
  }
</Row>

        </Container>
    </>
  );
}

export default ProductCard;